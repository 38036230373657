// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import '../../Styles/trippage.css';
// import { useLocation } from 'react-router-dom';
// import { FaSearch } from 'react-icons/fa';
// import { Helmet } from 'react-helmet';
// import { GetAllRegionAPI } from '../../Services/APIManager';
// const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

// export default function Region() {
//   const [regions, setRegions] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [filteredRegions, setFilteredRegions] = useState([]);
//   const navigate = useNavigate();
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   useEffect(() => {
//     const getRegions = async () => {
//       const data = await TotalRegionAPI(navigate); 
//       if (data && data.data) {
//         setRegions(data.data.regions);
//         setFilteredRegions(data.data.regions);
//       } else {
//         console.error('Failed to fetch regions');
//       }
//     };
//     getRegions();
//   }, [navigate]);

//   const handleSearchChange = (event) => {
//     const query = event.target.value;
//     setSearchQuery(query);
//     if (query === '') {
//       setFilteredRegions(regions);
//     }
//   };

//   const handleSearch = () => {
//     const filtered = regions.filter(region =>
//       region.regionId.toString().includes(searchQuery)
//     );
//     setFilteredRegions(filtered);
//   };

//   return (
//     <div className='region-page'>
//       <Helmet>
//         <title>Regions - GAIL Marker Surveillance System</title>
//         <meta name="description" content="Explore various regions and their details." />
//         <meta name="keywords" content="regions, GAIL, markers, trip details" />
//       </Helmet>
//       <div className='trip-page'>
//         <h3>Regions</h3>
//         <div className='search-container'>
//           <input
//             type='text'
//             placeholder='Search by Region ID'
//             value={searchQuery}
//             onChange={handleSearchChange}
//           />
//           <button onClick={handleSearch}>
//             <FaSearch />
//           </button>
//         </div>
//         <div className='trip-container'>
//           {filteredRegions.map((region, index) => (
//             <RegionCard key={index} region={region} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// function RegionCard({ region }) {
//   const [ref, inView] = useInView({ triggerOnce: true });

//   return (
//     <motion.div
//       ref={ref}
//       className='trip-card'
//       initial={{ opacity: 0, y: 50 }}
//       animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
//       transition={transition}
//     >
//       <h4>Region ID: {region.regionId}</h4>
//       <p><span>Region Name:</span> {region.regionName}</p>
//       <p><span>Total Markers:</span> {region.totalMarkers}</p>
//       <p><span>Missing Markers:</span> {region.missingMarkers}</p>
//       <p><span>Last Trip:</span> {region.lastTrip}</p>
//     </motion.div>
//   );
// }
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../../Styles/trippage.css';
import { useLocation } from 'react-router-dom';
import { GetAllRegionAPI } from '../../Services/APIManager';
import { FaSearch } from 'react-icons/fa';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Region() {
  const [regions, setRegions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredRegions, setFilteredRegions] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getRegions = async () => {
      try {
        const data = await GetAllRegionAPI();
        if (data && data.data) {
          // Filter out regions where deletedAt is not null
          const activeRegions = data.data.filter(region => region.deletedAt === null);
          setRegions(activeRegions);
          setFilteredRegions(activeRegions);
        } else {
          console.error('Failed to fetch regions');
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };
    getRegions();
  }, []);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === '') {
      setFilteredRegions(regions);
    }
  };

  const handleSearch = () => {
    const filtered = regions.filter(region =>
      region.regionId.toString().includes(searchQuery)
    );
    setFilteredRegions(filtered);
  };

  return (
    <HelmetProvider>
    <div className='region-page'>
      <Helmet>
        <title>Regions - GAIL Marker Surveillance System</title>
        <meta name="description" content="Explore various regions and their details." />
        <meta name="keywords" content="regions, GAIL, markers, trip details" />
      </Helmet>
      <div className='trip-page'>
        <h3>Regions</h3>
        <div className='search-container'>
          <input
            type='text'
            placeholder='Search by Region ID'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button onClick={handleSearch}>
            <FaSearch />
          </button>
        </div>
        <div className='trip-container'>
          {filteredRegions.map((region, index) => (
            <RegionCard key={index} region={region} />
          ))}
        </div>
      </div>
    </div>
    </HelmetProvider>
  );
}

function RegionCard({ region }) {
  const [ref, inView] = useInView({ triggerOnce: true });

  // Format date and time to be readable in UTC
  const formatDateTime = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      timeZone: 'UTC', 
      hour12: true  
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  return (
    <motion.div
      ref={ref}
      className='trip-card'
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={transition}
    >
      <h4>Region ID: {region.regionId}</h4>
      <p><span>Region Name:</span> {region.regionName}</p>
      <p><span>Created At:</span> {formatDateTime(region.createdAt)}</p>
      <p><span>Updated At:</span> {formatDateTime(region.updatedAt)}</p>
   
      {region.deletedAt ? (
        <p><span>Deleted At:</span> {formatDateTime(region.deletedAt)}</p>
      ) : (
        <p></p> 
      )}
    </motion.div>
      
  );
}
