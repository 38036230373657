import React, { useState, useEffect, useRef } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import GailLogo from '../../Assets/gailLogo.png';
import Profile from '../../Assets/profile.jpg';
import '../../Styles/header.css';
import '../../Styles/sidebar.css';
import ConfirmationPopup from '../SignOut/SignOut';
import { FaMapMarkerAlt, FaTachometerAlt, FaUser, FaMapMarkedAlt } from 'react-icons/fa';
import { FaMapPin, FaMapSigns, FaExclamationCircle, FaSignOutAlt, FaLocationArrow } from 'react-icons/fa';
import Footer from '../Footer/Footer';
import { GetNotification, GetReadNotification } from '../../Services/APIManager';

export default function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const dropdownRef = useRef(null);
  const confirmationRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    if (window.innerWidth <= 900) {
      setIsOpen(false);
    }
  };

  const handleSignOut = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSignOut = () => {
    localStorage.removeItem('webToken');
    window.location.href = '/login';
  };

  const handleCancelSignOut = () => {
    setShowConfirmation(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }

    if (isOpen && !event.target.closest('.sidebar') && !event.target.closest('.toggle-button')) {
      setIsOpen(false);
    }

    if (showConfirmation && confirmationRef.current && !confirmationRef.current.contains(event.target)) {
      setShowConfirmation(false);
    }
  };

  // Fetch unread notifications count
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        const response = await GetNotification();
        if (response && response.data) {
          setUnreadMessages(response.data); // Set unread message count
        }
      } catch (error) {
        console.error('Error fetching unread notifications:', error);
      }
    };

    fetchUnreadMessages();
  }, []);

 
  const handleIssuesClick = async () => {
    try {
      await GetReadNotification(); 
      setUnreadMessages(0); 
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
    closeSidebar();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, showDropdown, showConfirmation]);

  return (
    <div className="layout-container">
      <div className='navbar'>
        <div className='logo-toggle'>
          <div className='toggle-button' onClick={toggleSidebar}>
            <div className='line line1'></div>
            <div className='line line2'></div>
            <div className='line line3'></div>
          </div>
          <NavLink to="/" style={{ cursor: 'pointer' }}>
            <img src={GailLogo} alt='gail-logo' />
          </NavLink>
        </div>
        <div className='profile' onClick={toggleDropdown} ref={dropdownRef}>
          <div className='user-name'>Admin</div>
          <img src={Profile} alt='profile' />
          <div className={`dropdown ${showDropdown ? 'show' : ''}`}>
            <div className='dropdown-item' onClick={handleSignOut}>Sign out</div>
          </div>
        </div>
      </div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='menu'>
          <ul>
            <NavLink to="/dashboard" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
                <FaTachometerAlt />
                {isOpen && <span>Dashboard</span>}
              </li>
            </NavLink>
            <NavLink to="/getusers" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
                <FaUser />
                {isOpen && <span>Users</span>}
              </li>
            </NavLink>
            <NavLink to="/trip-page" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
                <FaMapMarkedAlt />
                {isOpen && <span>Trips</span>}
              </li>
            </NavLink>
            <NavLink to="/regions" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
              <FaMapPin />
              
                {isOpen && <span>Regions</span>}
              </li>
            </NavLink>
            <NavLink to="/markers-map" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
              <FaMapMarkerAlt />
                {isOpen && <span>Markers</span>}
              </li>
            </NavLink>
            <NavLink to="/markers" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
                <FaMapSigns />
                {isOpen && <span>Create Markers</span>}
              </li>
            </NavLink>
            <NavLink to="/livelocation" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
            
              <FaLocationArrow />
             
                {isOpen && <span>Live Location</span>}
              </li>
            </NavLink>
            <div className='line-separate'></div>
            <NavLink to="/issues" onClick={handleIssuesClick} className={({ isActive }) => isActive ? 'active' : ''}>
              <li>
             
                <FaExclamationCircle />
                {isOpen && <span>Issues</span>}
                {unreadMessages > 0 && (
                  <span className="notification-badge">{unreadMessages}</span>
                )}
              </li>
            </NavLink>
            <div onClick={handleSignOut}>
              <li onClick={closeSidebar}>
                <FaSignOutAlt />
                {isOpen && <span>Sign out</span>}
              </li>
            </div>
            
          </ul>
        </div>
      </div>
      <div className='content'>
        <Outlet />
        <Footer />
      </div>
      {showConfirmation && (
              <div ref={confirmationRef}>
                <ConfirmationPopup
                  message="Are you sure you want to end your session?"
                  onConfirm={handleConfirmSignOut}
                  onCancel={handleCancelSignOut}
                />
              </div>
            )}
    </div>
  );
}
