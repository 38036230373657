import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FaSearch, FaSync } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchMarkersByRegion, fetchMarkerById } from '../../Services/APIManager';
import PinImagesModal from '../MarkersMap/PinImagesPage';
import '../../Styles/mappage.css';
import Redmap from '../../Assets/pin-map.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import  Yellowmap from '../../Assets/gps (1).png';
import Bluemap from '../../Assets/location.png';
import Orangemap from '../../Assets/location (2).png';

// Create default icon
const defaultIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
  iconSize: [25, 31],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

// Create custom marker icon
const createCustomMarkerIcon = (iconUrl, iconSize = [40, 51]) => {
  return new L.Icon({
    iconUrl: iconUrl,
    iconRetinaUrl: iconUrl,
    iconSize: iconSize,
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });
};


const markerColors = {
  1: createCustomMarkerIcon(Bluemap, [35, 35]),
  2: createCustomMarkerIcon(Orangemap, [35, 35]),
  3: createCustomMarkerIcon(Yellowmap, [35, 35]),
};


const highlightedIcon = new L.Icon({
  iconUrl: Redmap,
  iconRetinaUrl: Redmap,
  iconSize: [35, 35],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

export default function MapPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [highlightedMarkers, setHighlightedMarkers] = useState([]);
  const [markersData, setMarkersData] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [regionInfo, setRegionInfo] = useState(null);
  const [mapCenter, setMapCenter] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const markerRefs = useRef({});
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchData = async (regionId, pageNumber = 1) => {
    try {
      const data = await fetchMarkersByRegion(regionId, pageNumber, navigate);
      if (data?.data?.markers) {
        const sortedMarkers = data.data.markers.sort((a, b) => a.markerId - b.markerId);
        if (pageNumber === 1) {
          setMarkersData(sortedMarkers);
          if (sortedMarkers.length > 0) {
            setMapCenter([parseFloat(sortedMarkers[0].latitude), parseFloat(sortedMarkers[0].longitude)]);
          }
        } else {
          setMarkersData((prevMarkers) => [...prevMarkers, ...sortedMarkers]);
        }
        setSearchPerformed(true);
        setHasMore(data.data.hasMore);
        setRegionInfo({ regionId, regionName: `Region ${regionId}` });
      } else {
        console.error('API returned an unexpected response:', data);
      }
    } catch (error) {
      console.error('Error fetching markers:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      fetchData(searchQuery);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleMarkerClick = async (markerId, latitude, longitude) => {
    try {
      const data = await fetchMarkerById(markerId, navigate);
      console.log("handleMarkerClick", data);
      if (data && data.data) {
        const markerDetails = {
          ...data.data,
          photos: data.data.MarkerPhotos || []
        };
        setSelectedMarker(markerDetails);
        setMapCenter([parseFloat(latitude), parseFloat(longitude)]);
        if (markerRefs.current[markerId]) {
          markerRefs.current[markerId].openPopup();
        }
      }
    } catch (error) {
      console.error('Error fetching marker details:', error);
    }
  };

  const handleCoordinateClick = (markerId, latitude, longitude) => {
    setHighlightedMarkers([markerId]);
    setMapCenter([parseFloat(latitude), parseFloat(longitude)]);
    
    // Highlight the clicked marker by changing the icon
    if (markerRefs.current[markerId]) {
      markerRefs.current[markerId].setIcon(highlightedIcon); // Change icon to highlighted
      markerRefs.current[markerId].openPopup(); // Open the popup
    }

    // Reset other markers' icons to default
    markersData.forEach(marker => {
      if (marker.markerId !== markerId && markerRefs.current[marker.markerId]) {
        markerRefs.current[marker.markerId].setIcon(markerColors[marker.markerCategoryId] || defaultIcon);
      }
    });
  };

  const loadMoreMarkers = () => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      fetchData(searchQuery, newPage);
      return newPage;
    });
  };

  const isMobile = window.innerWidth < 900;
  const sortedMarkers = isMobile ? [...markersData].reverse() : markersData;

  return (
    <HelmetProvider>
    <div className='map-page'>
      <Helmet>
        <title>Markers Map - GAIL Marker Surveillance System</title>
        <meta name="description" content="View and interact with markers on the map by region ID." />
        <meta name="keywords" content="map, markers, region, search, react, leaflet" />
      </Helmet>
      
      <h3>Markers</h3>
      <form onSubmit={handleSearchSubmit}>
        <div className='search-container'>
          <input
            type='text'
            placeholder='Enter region ID to search'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button type='submit'>
            <FaSearch />
          </button>
          <button type='button' onClick={handleRefresh}>
            <FaSync />
          </button>
        </div>
      </form>
      {searchPerformed &&
      <div className='map-icon-details'>

<div className='marker-legend'>
  <div className='legend-item'>
    <img src={markerColors[1].options.iconUrl} alt="Marker Category 1" className='pin-image' />
   Single Pipeline
  </div>
  <div className='legend-item'>
    <img src={markerColors[2].options.iconUrl} alt="Marker Category 2" className='pin-image' />
   Multiple Pipeline
  </div>
  <div className='legend-item'>
    <img src={markerColors[3].options.iconUrl} alt="Marker Category 3" className='pin-image' />
    Wall chamber
  </div>
</div>
</div>
}
      {searchPerformed && mapCenter && (
        <div className='content-container'>
          <div className='map-container'>
            <MapContainer center={mapCenter} zoom={15} style={{ height: '60vh', width: '100%' }}>
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
              {sortedMarkers.map((marker) => (
                <Marker
                  key={marker.markerId}
                  position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                  icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
                  ref={(ref) => {
                    if (ref) {
                      markerRefs.current[marker.markerId] = ref;
                    }
                  }}
                  eventHandlers={{
                    click: () => handleMarkerClick(marker.markerId, marker.latitude, marker.longitude),
                  }}
                >
                  <Popup>
                    <div>
                      <strong>Marker ID:</strong> {marker.markerId}<br />
                      <strong>Coordinates:</strong> {marker.latitude}, {marker.longitude}
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
          <div className='marker-list-container'>
            <div className='marker-list-header'>
              <h4>Markers</h4>
              <h4>Coordinates</h4>
            </div>
            <InfiniteScroll
              dataLength={sortedMarkers.length}
              next={loadMoreMarkers}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget='marker-list-container'
            >
              <div className='marker-list' id='marker-list-container'>
                {sortedMarkers.map((marker) => (
                  <div
                    key={marker.markerId}
                    className='marker-row'
                    onClick={() => handleCoordinateClick(marker.markerId, marker.latitude, marker.longitude)}
                    style={{ color: highlightedMarkers.includes(marker.markerId) ? 'red' : 'black' }}
                  >
                    <div>Pin {marker.markerId}</div>
                    <div>{`${marker.latitude}, ${marker.longitude}`}</div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}
 {regionInfo && (
        <div className='about-region' style={{marginTop:'20px'}}>
          <p>
            <span>Region ID:</span> {regionInfo.regionId}
          </p>
          <p>
            <span>Region Name:</span> {regionInfo.regionName}
          </p>
        </div>
      )}

      {selectedMarker && (
        <PinImagesModal marker={selectedMarker} handleClose={() => setSelectedMarker(null)} />
      )}
    </div>
     </HelmetProvider>
  );
}
