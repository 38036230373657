import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import '../../Styles/marker.css';
import MarkerDetails from './MarkerDetails';
import { CreateMarker, PhotoUploadAPI } from '../../Services/APIManager';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Markers = () => {
  const [tid, setTid] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [facingMode, setFacingMode] = useState('user');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPhotoConfirmed, setIsPhotoConfirmed] = useState(false);
  const [token, setToken] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(null);
  const [markerId, setMarkerId] = useState(null);
  const webcamRef = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('webtoken');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    if (photo && latitude && longitude && isPhotoConfirmed) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  }, [tid, photo, latitude, longitude, isPhotoConfirmed]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setErrorStatus(null); 
    if (formComplete) {
      UploadMarkerPhoto();
    } else {
      const unfilledInputs = [];
      if (!tid) {
        unfilledInputs.push('Marker Details');
      }
      if (!latitude || !longitude) {
        unfilledInputs.push('Location');
      }
      if (!photo) {
        unfilledInputs.push('Photo');
      }
      const errorMessage = `Please fill the following input(s): ${unfilledInputs.join(', ')}`;
      setErrorMessage(errorMessage);
      alert(errorMessage);
    }
  };

  const handleTakePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
        setPhoto(file);
        setPhotoURL(URL.createObjectURL(blob));
        setShowWebcam(false);
        setIsPhotoConfirmed(true);
      })
      .catch(error => {
        console.error('Error converting data URL to Blob:', error);
      });
  };

  const handleRetakePhoto = () => {
    setPhoto(null);
    setPhotoURL(null);
    setShowWebcam(true);
  };

  const handleConfirmPhoto = () => {
    setIsPhotoConfirmed(true);
  };

  const toggleFacingMode = () => {
    setFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          alert('Error obtaining location. Please ensure location services are enabled and permission is granted.');
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const FileCreation = async (photoUrl) => {
    try {
      let res = await CreateMarker(tid,latitude, longitude, photoUrl,token);
      console.log("filecreation token",token)
      console.log("file creation response", res)
      console.log(res.response.status===201)
      if (res && res.response.status === 201) {
        setSuccessMessage("Marker File created successfully");
        toast.success("Marker File created successfully");
        setIsSubmitted(true);
        setMarkerId(res.resultData.data.markerId);
      }  
      if (res.response.status === 401) {
        localStorage.clear();
    navigate("/login");
      } 
      else {
        console.log("File creation failed");
        setErrorStatus(res?.status);
        toast.error(res?.message || "File creation failed");
      }
    } catch (e) {
      console.log("error in file creation", e);
      setErrorStatus(e?.response?.status);
      toast.error("Error in file creation");
      return null;
    }
  };

  const UploadMarkerPhoto = async () => {
    try {
      let res = await PhotoUploadAPI(photo, token);
      console.log("UploadMarkerPhoto", res)
      if (res?.response?.status == 401) {
        navigate("/login")
        localStorage.clear()
        toast.error("Invalid or expired token. Please Login again !");
        return
      }

      if (res?.resultData?.data?.photoUrl) {
        await FileCreation(res?.resultData?.data?.photoUrl);
      } else {
        setErrorStatus(res?.response?.status);
        toast.error("Photo upload unsuccessful");
      }
    } catch (e) {
      console.log("error in file creation", e);
      setErrorStatus(500);
      toast.error("Error in photo upload");
      return null;
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <HelmetProvider>
    <div className='marker-location'>
       <Helmet>
        <title>Create Marker- GAIL Marker Surveillance System</title>
        <meta name="description" content="Create a new marker by capturing a photo and detecting location." />
      </Helmet>
      <ToastContainer />
      <h3>Create Markers</h3>
      <form onSubmit={handleSubmit}>
        <div className='markerform-details'>
          <label htmlFor="tid">Enter TID:</label>
          <input
            type="text"
            id="tid"
            value={tid}
            onChange={(e) => setTid(e.target.value)}
          />
        </div>
        <div className='markerform-details'>
          <label htmlFor="latitude">Click here to detect your location :</label>
          <button className='location' type="button" onClick={handleLocationClick}>Select location</button>
        </div>
        {latitude && longitude && (
          <span>Latitude: {latitude}, Longitude: {longitude}</span>
        )}
        <div className='markerform-details'>
          <label htmlFor="photo" >Click Marker photo :</label>
          <button className='choose-file' type="button" onClick={() => setShowWebcam(true)}>Take Photo</button>
        </div>
        {showWebcam && (
          <div className='webcam-container'>
            <Webcam style={{ width: '95%' }}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode }}
              className='webcam'
            />
            <div className='webcam-controls'>
              <button className='toggle-camera' type="button" onClick={toggleFacingMode}>Switch Camera</button>
              <button className='take-photo' type="button" onClick={handleTakePhoto}>Take Photo</button>
            </div>
          </div>
        )}
        {photoURL && (
          <div>
            <img src={photoURL} alt="Captured" className='photo-preview' />
            {!isPhotoConfirmed && (
              <div className='photo-confirm'>
                <button className='retake' type="button" onClick={handleRetakePhoto}>Retake</button>
                <button className='confirm' type="button" onClick={handleConfirmPhoto}>Confirm</button>
              </div>
            )}
          </div>
        )}
        {errorMessage && <div className='error-message'>{errorMessage}</div>}
        {successMessage && <div className='success-message'>{successMessage}</div>}
        <button type="submit" className='marker-submit' disabled={!formComplete}>Create Marker</button>
        <button className='refresh' onClick={handleRefresh}> Clear </button>
      </form>
      {isSubmitted ? (
        <MarkerDetails tid={tid} markerId={markerId} photo={photo} latitude={latitude} longitude={longitude} />
      ):<></>}
      {errorStatus && (
        <div className="error-message">
          {errorStatus === 401 || errorStatus === 500 ? "An error occurred. Please try again later." : errorMessage}
        </div>
      )}
    </div>
    </HelmetProvider>
  );
};

export default Markers;


// import React, { useState, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import '../../Styles/marker.css';
// import MarkerDetails from './MarkerDetails';
// import { CreateMarker, PhotoUploadAPI } from '../../Services/APIManager';
// import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// const Markers = () => {
//   const [markerId, setMarkerId] = useState('');
//   const [photo, setPhoto] = useState(null);
//   const [photoURL, setPhotoURL] = useState(null);
//   const [showWebcam, setShowWebcam] = useState(false);
//   const [facingMode, setFacingMode] = useState('user');
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isPhotoConfirmed, setIsPhotoConfirmed] = useState(false);
//   const [token, setToken] = useState(null);
//   const [formComplete, setFormComplete] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorStatus, setErrorStatus] = useState(null); 
//   const webcamRef = useRef(null);
//   let navigate = useNavigate();

//   useEffect(() => {
//     const storedToken = localStorage.getItem('webtoken');
//     console.log("token in marker")
//     setToken(storedToken);
//   }, []);
  

//   useEffect(() => {
//     if (photo && latitude && longitude && isPhotoConfirmed) {
//       setFormComplete(true);
//     } else {
//       setFormComplete(false);
//     }
//   }, [markerId, photo, latitude, longitude, isPhotoConfirmed]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setIsSubmitted(true);
//     setErrorStatus(null); 
//     if (formComplete) {
//       UploadMarkerPhoto();
//     } else {
//       const unfilledInputs = [];
//       if (!markerId) {
//         unfilledInputs.push('Marker Details');
//       }
//       if (!latitude || !longitude) {
//         unfilledInputs.push('Location');
//       }
//       if (!photo) {
//         unfilledInputs.push('Photo');
//       }
//       const errorMessage = `Please fill the following input(s): ${unfilledInputs.join(', ')}`;
//       setErrorMessage(errorMessage);
//       alert(errorMessage);
//     }
//   };

//   const handleTakePhoto = () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     fetch(imageSrc)
//       .then(response => response.blob())
//       .then(blob => {
//         const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
//         setPhoto(file);
//         setPhotoURL(URL.createObjectURL(blob));
//         setShowWebcam(false);
//         setIsPhotoConfirmed(true);
//       })
//       .catch(error => {
//         console.error('Error converting data URL to Blob:', error);
//       });
//   };

//   const handleRetakePhoto = () => {
//     setPhoto(null);
//     setPhotoURL(null);
//     setShowWebcam(true);
//   };

//   const handleConfirmPhoto = () => {
//     setIsPhotoConfirmed(true);
//   };

//   const toggleFacingMode = () => {
//     setFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
//   };

//   const handleLocationClick = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLatitude(position.coords.latitude);
//           setLongitude(position.coords.longitude);
//         },
//         (error) => {
//           console.error("Error obtaining location:", error);
//           alert('Error obtaining location. Please ensure location services are enabled and permission is granted.');
//         },
//         {
//           enableHighAccuracy: true,
//           timeout: 10000,
//           maximumAge: 0
//         }
//       );
//     } else {
//       alert('Geolocation is not supported by this browser.');
//     }
//   };

//   const FileCreation = async (photoUrl) => {
//     try {
//       let res = await CreateMarker(latitude, longitude, photoUrl, token);
//       if (res) {
//         setSuccessMessage("Marker File created successfully");
//         toast.success("Marker File created successfully");
//         if (res.status === 201) {
//           setIsSubmitted(true);
//         }
//       } else {
//         console.log("File creation failed");
//         setErrorStatus(res?.status); 
//         toast.error("File creation failed");
//       }
//     } catch (e) {
//       console.log("error in file creation", e);
//       setErrorStatus(e?.response?.status); 
//       toast.error("Error in file creation");
//       return null;
//     }
//   };

//   const UploadMarkerPhoto = async () => {
//     try {
//       let res = await PhotoUploadAPI(photo, token);
//       console.log("UploadMarkerPhoto", res)
//         if (res?.response?.status == 401) {
//           navigate("/login")
//           localStorage.clear()
//           toast.error("Invalid or expired token. Please Login again !");
//           return
//         }
      
//       if (res?.resultData?.data?.photoUrl) {
//         await FileCreation(res?.resultData?.data?.photoUrl);
//       } else {
//         setErrorStatus(res?.response?.status); 
//         toast.error("Photo upload failed");
//       }
//     } catch (e) {
//       console.log("error in file creation", e);
//       setErrorStatus(500); 
//       toast.error("Error in photo upload");
//       return null;
//     }
//   };

//   const handleRefresh = () => {
//     window.location.reload();
//   };

//   return (
//     <div className='marker-location'>
//       <ToastContainer />
//       <h3>Create Markers</h3>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label htmlFor="markerId">Enter TID:</label>
//           <input
//             type="text"
//             id="markerId"
//             value={markerId}
//             onChange={(e) => setMarkerId(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="latitude">Click here to detect your location :</label>
//           <button className='location' type="button" onClick={handleLocationClick}>Select location</button>
//         </div>
//         {latitude && longitude && (
//           <span>Latitude: {latitude}, Longitude: {longitude}</span>
//         )}
//         <div>
//           <label htmlFor="photo">Click Marker photo :</label>
//           <button className='choose-file' type="button" onClick={() => setShowWebcam(true)}>Take Photo</button>
//         </div>
//         {showWebcam && (
//           <div className='webcam-container'>
//             <Webcam style={{ width: '95%' }}
//               audio={false}
//               ref={webcamRef}
//               screenshotFormat="image/jpeg"
//               videoConstraints={{ facingMode }}
//               className='webcam'
//             />
//             <div className='webcam-controls'>
//               <button className='choose-file' type="button" onClick={handleTakePhoto}>Capture</button>
//               <button className='choose-file' type="button" onClick={toggleFacingMode}>Switch Camera</button>
//             </div>
//           </div>
//         )}
//         {photoURL && (
//           <div>
//             <img src={photoURL} alt="Captured" style={{ width: '300px', height: '300px', margin: 'auto' }} />
//             {!isPhotoConfirmed && (
//               <div>
//                 <button className='choose-file' type="button" onClick={handleRetakePhoto}>Retake</button>
//                 <button className='choose-file' type="button" onClick={handleConfirmPhoto}>Done</button>
//               </div>
//             )}
//           </div>
//         )}
//         {errorMessage && <div className='error-message'>{errorMessage}</div>}
//         {successMessage && <div className='success-message'>{successMessage}</div>}
//         <button type="submit" className='marker-submit' disabled={!formComplete}>Create Marker</button>
//         <button className='refresh' onClick={handleRefresh}> Clear </button>
//       </form>
//       {isSubmitted ? (
//         <MarkerDetails markerId={markerId} photo={photo} latitude={latitude} longitude={longitude} />
//       ):<></>}
//       {errorStatus && (
//         <div className="error-message">
//           {errorStatus === 401 || errorStatus === 500 ? "An error occurred. Please try again later." : errorMessage}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Markers;



