// import React, { useState, useEffect } from 'react';
// import '../../Styles/users.css'; // Import user.css for styling
// import { UsersAPI } from '../../Services/APIManager';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { FaSearch,FaTimes } from 'react-icons/fa';
// const User = () => {
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await UsersAPI();

//         if (response.status === 401) {
//           window.location.href = '/login';
//           return;
//         }

//         if (response.data) {
//           const fetchedUsers = response.data.users.map(user => ({
//             userId: user.userId.toString(),
//             username: user.userName, 
//             role: user.role || 'N/A', 
//             region: Array.isArray(user.regions) ? user.regions.join(', ') : 'N/A',
//             fullName: `${user.firstName} ${user.lastName}`, 
//             createdAt: user.createdAt
//       ? new Date(user.createdAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' })
//       : 'N/A',
//     updatedAt: user.updatedAt
//       ? new Date(user.updatedAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' })
//       : 'N/A',
//           }));
//           setUsers(fetchedUsers);
//           setFilteredUsers(fetchedUsers);
//         } else {
//           console.error('No data found');
//         }
//       } catch (error) {
//         console.error('An error occurred:', error);
//       }
//     };

//     fetchUsers();
//   }, []);

//   const handleUserSelect = (userId) => {
//     const selected = users.find(user => user.userId === userId);
//     setSelectedUser(selected);
//   }; 

//   const handleSearchChange = (event) => {
//     const searchTerm = event.target.value;
//     setSearchTerm(searchTerm);
//     filterUsers(searchTerm);
//   };

//   const filterUsers = (term) => {
//     if (!term.trim()) {
//       setFilteredUsers(users);
//     } else {
//       const filtered = users.filter(user =>
//         user.userId.toLowerCase().includes(term.toLowerCase()) ||
//         user.username.toLowerCase().includes(term.toLowerCase()) ||
//         user.role.toLowerCase().includes(term.toLowerCase()) ||
//         user.region.toLowerCase().includes(term.toLowerCase())
//       );
//       setFilteredUsers(filtered);
//     }
//   };

//   const handleClose = () => {
//     setSelectedUser(null);
//   };

//   return (
//     <HelmetProvider>
//       <div className="user-container">
//         <Helmet>
//           <title>User Management - GAIL Marker Surveillance System</title>
//           <meta name="description" content="User management dashboard for GAIL Marker Surveillance System including search and detailed user information." />
//           <meta name="keywords" content="GAIL, User Management, Marker Surveillance, User Details, Search Users" />
//         </Helmet>
//         <h3 style={{marginBottom:'20px'}}>Riders Info</h3>
//         <div className="search-container">
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleSearchChange}
//           />
//           <button><FaSearch/></button> 
//         </div>
//         {selectedUser && (
//           <div className="selected-user" onClick={handleClose}>
//             <div className="selected-card">
//               <h3>User Details</h3>
//               <button className="close-button" onClick={handleClose}><FaTimes /></button>
//               <p><span>User ID: </span>{selectedUser.userId}</p>
//               <p><span>Username:</span> {selectedUser.username}</p>
//               <p><span>Role:</span> {selectedUser.role}</p>
             
//               <p><span>Full Name:</span> {selectedUser.fullName}</p>
//               <p><span>Created At:</span> {selectedUser.createdAt}</p>
//               <p><span>Updated At:</span> {selectedUser.updatedAt}</p>
//             </div>
//           </div>
//         )}
//         <div className="usercard-container">
//           {filteredUsers.map(user => (
//             <div className="usercard" key={user.userId} onClick={() => handleUserSelect(user.userId)}>
//               <h4>{user.username}</h4>
//               <p><span>User ID: </span>{user.userId}</p>
//               <p><span>Role: </span>{user.role}</p>
//               <p><span>Region:</span> {user.region}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </HelmetProvider>
//   );
// };

// export default User;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../../Styles/users.css'; // Import user.css for styling
import { UsersAPI } from '../../Services/APIManager';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaSearch, FaTimes } from 'react-icons/fa';

const User = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await UsersAPI();

        if (response.status === 401) {
          navigate('/login');
          return;
        }

        if (response.data) {
          const fetchedUsers = response.data.users.map(user => ({
            userId: user.userId.toString(),
            username: user.userName, 
            role: user.role || 'N/A', 
            region: Array.isArray(user.regions) ? user.regions.join(', ') : 'N/A',
            fullName: `${user.firstName} ${user.lastName}`, 
            createdAt: user.createdAt
              ? new Date(user.createdAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' })
              : 'N/A',
            updatedAt: user.updatedAt
              ? new Date(user.updatedAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' })
              : 'N/A',
          }));
          setUsers(fetchedUsers);
          setFilteredUsers(fetchedUsers);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchUsers();
  }, [navigate]);

  const handleUserSelect = (userId) => {
    const selected = users.find(user => user.userId === userId);
    setSelectedUser(selected);
  }; 

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterUsers(searchTerm);
  };

  const filterUsers = (term) => {
    if (!term.trim()) {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(user =>
        user.userId.toLowerCase().includes(term.toLowerCase()) ||
        user.username.toLowerCase().includes(term.toLowerCase()) ||
        user.role.toLowerCase().includes(term.toLowerCase()) ||
        user.region.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  const handleClose = () => {
    setSelectedUser(null);
  };

  return (
    <HelmetProvider>
      <div className="user-container">
        <Helmet>
          <title>User Management - GAIL Marker Surveillance System</title>
          <meta name="description" content="User management dashboard for GAIL Marker Surveillance System including search and detailed user information." />
          <meta name="keywords" content="GAIL, User Management, Marker Surveillance, User Details, Search Users" />
        </Helmet>
        <h3 style={{marginBottom:'20px'}}>Riders Info</h3>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button><FaSearch/></button> 
        </div>
        {selectedUser && (
          <div className="selected-user" onClick={handleClose}>
            <div className="selected-card">
              <h3>User Details</h3>
              <button className="close-button" onClick={handleClose}><FaTimes /></button>
              <p><span>User ID: </span>{selectedUser.userId}</p>
              <p><span>Username:</span> {selectedUser.username}</p>
              <p><span>Role:</span> {selectedUser.role}</p>
             
              <p><span>Full Name:</span> {selectedUser.fullName}</p>
              <p><span>Created At:</span> {selectedUser.createdAt}</p>
              <p><span>Updated At:</span> {selectedUser.updatedAt}</p>
            </div>
          </div>
        )}
        <div className="usercard-container">
          {filteredUsers.map(user => (
            <div className="usercard" key={user.userId} onClick={() => handleUserSelect(user.userId)}>
              <h4>{user.username}</h4>
              <p><span>User ID: </span>{user.userId}</p>
              <p><span>Role: </span>{user.role}</p>
              <p><span>Region:</span> {user.region}</p>
            </div>
          ))}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default User;
