// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { CaseById, GetCaseHistory } from '../../Services/APIManager';
// import '../../Styles/issuedetail.css';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// const IssueDetailsPage = () => {
//   const { caseId } = useParams();
//   const [issueDetails, setIssueDetails] = useState(null);
//   const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
//   const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
//   const [caseHistory, setCaseHistory] = useState([]);
//   const [showHistoryCard, setShowHistoryCard] = useState(false);
//   const navigate = useNavigate();

//   const handleViewOnMap = () => {
//     if (issueDetails && issueDetails.latitude && issueDetails.longitude) {
//       navigate('/issuemap', {
//         state: {
//           caseId:issueDetails.caseId,
//           caseId:caseData.caseId,
//           latitude: issueDetails.latitude,
//           longitude: issueDetails.longitude,
//         },
//       });
//     }
//   };

//   const handlePhotoClick = () => {
//     setIsPhotoModalOpen(true);
//   };

//   const closePhotoModal = () => {
//     setIsPhotoModalOpen(false);
//     setCurrentPhotoIndex(0);
//   };

//   const handleNextPhoto = () => {
//     if (issueDetails) {
//       const totalPhotos = issueDetails.casePhotos?.length || 0;
//       setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % totalPhotos);
//     }
//   };

//   const handlePreviousPhoto = () => {
//     if (issueDetails) {
//       const totalPhotos = issueDetails.casePhotos?.length || 0;
//       setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + totalPhotos) % totalPhotos);
//     }
//   };

//   useEffect(() => {
//     const fetchIssueDetails = async () => {
//       try {
//         const details = await CaseById(caseId);
//         setIssueDetails(details);
//       } catch (error) {
//         console.error('Error fetching issue details:', error);
//       }
//     };
//     fetchIssueDetails();
//   }, [caseId]);

//   const handleViewHistory = async () => {
//     try {
//       const historyData = await GetCaseHistory(caseId);
//       setCaseHistory(historyData.data);
//       setShowHistoryCard(true);
//     } catch (error) {
//       console.error('Error fetching case history:', error);
//     }
//   };

//   const getCurrentPhoto = () => {
//     return issueDetails.casePhotos?.[currentPhotoIndex]?.casePhotoUrl || '';
//   };

//   if (!issueDetails) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="issue-details">
//       <h1>Issue Details for Case ID: {issueDetails.caseId}</h1>
//       <div className="issuedetail-content">
//         <div className="issue-content">
//           <p><strong>MarkerID:</strong> {issueDetails.markerId}</p>
//           <p><strong>User ID:</strong> {issueDetails.userId}</p>
//           <p><strong>Trip ID:</strong> {issueDetails.tripId}</p>
//           <p><strong>Latitude:</strong> {issueDetails.latitude}</p>
//           <p><strong>Longitude:</strong> {issueDetails.longitude}</p>
//           <p><strong>Created At:</strong> {new Date(issueDetails.createdAt).toLocaleString()}</p>
//           <p><strong>Updated At:</strong> {new Date(issueDetails.updatedAt).toLocaleString()}</p>
//           {issueDetails.deletedAt && (
//             <p><strong>Deleted At:</strong> {new Date(issueDetails.deletedAt).toLocaleString()}</p>
//           )}

//           <p>
//             <strong>Photos Clicked:</strong>
//             <span
//               className="photo-count"
//               onClick={handlePhotoClick}
//               onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
//               onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
//             >
//               {issueDetails.casePhotos?.length || 0}
//             </span>
//           </p>
//         </div>
//       </div>

//       {/* Photo Modal */}
//       {isPhotoModalOpen && (
//         <div className="photo-modal">
//           <div className="issuemodal-content">
//             <div className="photo-navigation">
//               <FaChevronLeft onClick={handlePreviousPhoto} className="nav-icon" />
//               {issueDetails.casePhotos?.length > 0 ? (
//                 <img
//                   src={getCurrentPhoto()}
//                   alt={`Photo ${currentPhotoIndex + 1}`}
//                   className="full-size-photo"
//                 />
//               ) : (
//                 <p>No photos available</p>
//               )}
//               <FaChevronRight onClick={handleNextPhoto} className="nav-icon" />
//             </div>
//             <button onClick={closePhotoModal} className="close-modal-button">Close</button>
//           </div>
//         </div>
//       )}

//       {/* Case History Section */}
//       {showHistoryCard && (
//         <div className="history-card">
//           <h2>Case History</h2>
//           <div className="case-history-list">
//             {caseHistory.length > 0 ? (
//               caseHistory.map((history, index) => (
//                 <div key={history.caseHistoryId} className="history-item">
//                   <div>
//                   <p><strong>Case History ID:</strong> {history.caseHistoryId}</p>
//                   <p><strong>Case Status:</strong> {history.caseStatus}</p>
//                   <p><strong>Created At:</strong> {new Date(history.createdAt).toLocaleString()}</p>
//                   <p><strong>Updated At:</strong> {new Date(history.updatedAt).toLocaleString()}</p>
//                   </div>
//                   {history.casePhoto && (
//                     <img
//                       src={history.casePhoto.casePhotoUrl}
//                       alt={`History Photo ${index + 1}`}
//                       className="history-photo"
//                     />
//                   )}
//                 </div>
//               ))
//             ) : (
//               <p>No history available for this case.</p>
//             )}
//           </div>
//         </div>
//       )}

//       <div className="button-group">
//         <button className="back-button" onClick={handleViewHistory}>View History</button>
//         <button className="map-button" onClick={handleViewOnMap}>View on Map</button>
//       </div>
//     </div>
//   );
// };

// export default IssueDetailsPage;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CaseById, GetCaseHistory } from '../../Services/APIManager';
import '../../Styles/issuedetail.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const IssueDetailsPage = () => {
  const { caseId } = useParams();
  const [issueDetails, setIssueDetails] = useState(null);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [caseHistory, setCaseHistory] = useState([]);
  const [showHistoryCard, setShowHistoryCard] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  const handleViewOnMap = () => {
    if (issueDetails && issueDetails.latitude && issueDetails.longitude) {
      navigate('/livelocation');
    }
  };

  const handlePhotoClick = () => {
    setIsPhotoModalOpen(true);
  };

  const closePhotoModal = () => {
    setIsPhotoModalOpen(false);
    setCurrentPhotoIndex(0);
  };

  const handleNextPhoto = () => {
    if (issueDetails) {
      const totalPhotos = issueDetails.casePhotos?.length || 0;
      setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % totalPhotos);
    }
  };

  const handlePreviousPhoto = () => {
    if (issueDetails) {
      const totalPhotos = issueDetails.casePhotos?.length || 0;
      setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + totalPhotos) % totalPhotos);
    }
  };

  useEffect(() => {
    const fetchIssueDetails = async () => {
      try {
        setLoading(true);
        const details = await CaseById(caseId);
        setIssueDetails(details);
      } catch (error) {
        console.error('Error fetching issue details:', error);
        setError('Failed to load issue details.');
      } finally {
        setLoading(false);
      }
    };
    fetchIssueDetails();
  }, [caseId]);

  const handleViewHistory = async () => {
    try {
      const historyData = await GetCaseHistory(caseId);
      setCaseHistory(historyData.data);
      setShowHistoryCard(true);
    } catch (error) {
      console.error('Error fetching case history:', error);
      setError('Failed to load case history.');
    }
  };

  const getCurrentPhoto = () => {
    return issueDetails.casePhotos?.[currentPhotoIndex]?.casePhotoUrl || '';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="issue-details">
      <h1>Issue Details for Case ID: {issueDetails.caseId}</h1>
      <div className="issuedetail-content">
        <div className="issue-content">
          <p><strong>Marker ID:</strong> {issueDetails.markerId}</p>
          <p><strong>User ID:</strong> {issueDetails.userId}</p>
          <p><strong>Trip ID:</strong> {issueDetails.tripId}</p>
          <p><strong>Latitude:</strong> {issueDetails.latitude}</p>
          <p><strong>Longitude:</strong> {issueDetails.longitude}</p>
          <p><strong>Created At:</strong> {new Date(issueDetails.createdAt).toLocaleString()}</p>
          <p><strong>Updated At:</strong> {new Date(issueDetails.updatedAt).toLocaleString()}</p>
          {issueDetails.deletedAt && (
            <p><strong>Deleted At:</strong> {new Date(issueDetails.deletedAt).toLocaleString()}</p>
          )}

          <p>
            <strong>Photos Clicked:</strong>
            <span
              className="photo-count"
              onClick={handlePhotoClick}
              onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
              onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
            >
              {issueDetails.casePhotos?.length || 0}
            </span>
          </p>
        </div>
      </div>

      {/* Photo Modal */}
      {isPhotoModalOpen && (
        <div className="photo-modal">
          <div className="issuemodal-content">
            <div className="photo-navigation">
              <FaChevronLeft onClick={handlePreviousPhoto} className="nav-icon" />
              {issueDetails.casePhotos?.length > 0 ? (
                <img
                  src={getCurrentPhoto()}
                  alt={`Photo ${currentPhotoIndex + 1}`}
                  className="full-size-photo"
                />
              ) : (
                <p>No photos available</p>
              )}
              <FaChevronRight onClick={handleNextPhoto} className="nav-icon" />
            </div>
            <button onClick={closePhotoModal} className="close-modal-button">Close</button>
          </div>
        </div>
      )}

      {/* Case History Section */}
      {showHistoryCard && (
        <div className="history-card">
          <h2>Case History</h2>
          <div className="case-history-list">
            {caseHistory.length > 0 ? (
              caseHistory.map((history) => (
                <div key={history.caseHistoryId} className="history-item">
                  <div>
                    <p><strong>Case History ID:</strong> {history.caseHistoryId}</p>
                    <p><strong>Case Status:</strong> {history.caseStatus}</p>
                    <p><strong>Created At:</strong> {new Date(history.createdAt).toLocaleString()}</p>
                    <p><strong>Updated At:</strong> {new Date(history.updatedAt).toLocaleString()}</p>
                  </div>
                  {history.casePhoto && (
                    <img
                      src={history.casePhoto.casePhotoUrl}
                      alt={`History Photo`}
                      className="history-photo"
                    />
                  )}
                </div>
              ))
            ) : (
              <p>No history available for this case.</p>
            )}
          </div>
        </div>
      )}

      <div className="button-group">
        <button className="back-button" onClick={handleViewHistory}>View History</button>
        <button className="map-button" onClick={handleViewOnMap}>View on Map</button>
      </div>
    </div>
  );
};

export default IssueDetailsPage;
