import config from "../config";
import { useNavigate } from 'react-router-dom';
const DEFAULT_URL = config.BASE_URL;
const GAILPLOT_URL = config.GAIL_URL;

export const TripDetailsAPI = async (tripId,navigate) => {
  try {
    const TripList = await fetch(DEFAULT_URL + `getTripDetails/?tripId=${tripId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!TripList.ok) {
      if (TripList.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch Trip Details');
    }

    const resultData = await TripList.json();
    return resultData;
  } catch (e) {
    console.log("Error in getting Trip Details", e);
    return null;
  }
};
  export const LoginAPI = async (username, password,navigate) => {
    const token = localStorage.getItem('webtoken');
   
    try {
      const requestBody = {
        userName: username, 
        password: password
      };

      const loginResult = await fetch(DEFAULT_URL + "users/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
      });
      if (loginResult.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate("/login"); 
        throw new Error("Unauthorized. Redirecting to login.");
      }
  
      if (!loginResult.ok) {
        throw new Error("Failed to login");
      }
     

      const resultData = await loginResult.json();
      // console.log("Login Successful", resultData);
      return resultData;
    } catch (e) {
      console.error("Error in Login", e);
      return null;
    }
  };


export const PhotoUploadAPI = async (photo, token,navigate) => {
  try {
    const formData = new FormData();
    formData.append('photo', photo);

    const response = await fetch(GAILPLOT_URL + "upload-photo", {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData,
      redirect: "follow"
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
       
        window.location.reload(); 
        navigate('/login');
        return;
      }

      throw new Error('Failed to upload a photo');
    }

    const resultData = await response.json();
    //  console.log("Photo uploaded successfully", resultData);
    return {
      response: response, 
      resultData:resultData 
    };

  } catch (e) {
    console.log("Error in photo upload", e);
    return 401;
  }
};
export const CreateMarker = async (tripId, latitude, longitude, photoUrl, token,navigate) => {
  try {
    const requestBody = {
      tid: tripId,
      latitude: latitude,
      longitude: longitude,
      photoUrl: photoUrl
    };

    const response = await fetch(GAILPLOT_URL + "markers", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to create a marker ');
    }
    const resultData = await response.json();
    // console.log("Marker creation response:", resultData);

    return {
      response: response,
      resultData: resultData
    };
  } catch (e) {
    console.error("Error in creating Marker:", e);
    return null;
  }
};


export const StatusAPI = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
      
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error('Token not found. Redirecting to login.');
    }
    const response = await fetch(DEFAULT_URL + 'users/dashboard', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch status api');
    }
    const resultData = await response.json();
    return resultData;
  } catch (error) {
    console.error('Error in StatusAPI:', error);
    if (error.status === 401) {
      throw error;
    }
    return { error: error.message }; 
  }
};

export const GetUsersAPI = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
      
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error('Token not found. Redirecting to login.');
    }
    const response = await fetch(DEFAULT_URL + 'users/dashboard', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch  status api');
    }
    const resultData = await response.json();
    return resultData;
  } catch (error) {
    console.error('Error in StatusAPI:', error);
    return { error: error.message }; 
  }
};
export const TripsAPI = async (page, limit,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
      
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error('Token not found. Redirecting to login.');
    }
    const response = await fetch(`${DEFAULT_URL}trips/search?p=${page}&l=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetchtrip details');
    }

    const resultData = await response.json();
    return resultData;
  } catch (e) {
    console.log("Error in getting Trip Details", e);
    return null;
  }
};

export const UsersAPI = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    
    // console.log("userapi token", token);
     
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error('Token not found. Redirecting to login.');
    }
    const UserList = await fetch(DEFAULT_URL + "users/all", { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ngrok-skip-browser-warning': 'skip-browser-warning',
        
      },
    });
    // console.log("Userlist", UserList);
    
    if (!UserList.ok) {
      if (UserList.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch user api');
    }
    const resultData = await UserList.json();
    // console.log("Users API", resultData);
    return resultData;

  } catch (e) {
    console.log("Error in getting User Details", e.stack);
    return { status: e.status || 500, message: e.message || "Error" };
  }
};
export const fetchMarkersByRegion = async (regionId, navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }

    const response = await fetch(`${DEFAULT_URL}marker/region/${regionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch markers for region');
    }
    const responseData = await response.json();
    // console.log('Markers for region:', responseData);

    return responseData;
  } catch (error) {
    console.error(`Error fetching markers for region ${regionId}:`, error);
    return null;
  }
};
export const fetchAllMarkers = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }

    const response = await fetch(`${DEFAULT_URL}marker`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch All markers');
    }
    const responseData = await response.json();
    // console.log('All markers:', responseData);

    return responseData;
  } catch (error) {
    console.error(`Error fetching all markers:`, error);
    return null;
  }
};
export const fetchMarkerById = async (markerId, navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }

    const response = await fetch(`${DEFAULT_URL}marker/${markerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetching marker with id');
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error(`Error fetching marker with id ${markerId}:`, error);
    return null;
  }
};


export const GetMarkersByIDAPI = async (markerId,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(DEFAULT_URL+"markers/${markerId}", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const responseText = await response.text();
    console.log('Response text:', responseText); 
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch marker details');
    }
    try {
      const resultData = JSON.parse(responseText);
      return resultData;
    } catch (jsonError) {
      throw new Error(`Invalid JSON response: ${responseText}`);
    }
  } catch (e) {
    console.log("Error in getting Marker Details", e.message); 
    return null;
  }
};

export const tripfetchMarkersById = async (markerId,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(`${DEFAULT_URL}markers/${markerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch marker with id');
    }

    const responseData = await response.json();
    return responseData.data; 
  } catch (error) {
    console.error(`Error fetching marker with id ${markerId}:`, error);
    throw error;
  }
};

export const AllCaseAPI = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(DEFAULT_URL + "case/", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch marker with case api');
    }

    const responseData = await response.json();


    return responseData; 
  } catch (error) {
    console.error("Error fetching marker with case api", error);
    throw error;
  }
};

export const CaseById = async (caseId,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(`${DEFAULT_URL}case/${caseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch single case api');
    }
    const responseData = await response.json();
    

    return responseData.data;
  } catch (error) {
    console.error(`Error fetching marker with id ${caseId}:`, error);
    throw error;
  }
};


export const GetMarkersByRegion = async (regionId, navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    if (!token) {
      throw new Error("No web token found in local storage");
    }

    const response = await fetch(`${DEFAULT_URL}marker/region/${regionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch marker by region');
    }

    const resultData = await response.json();
    return resultData;
  } catch (e) {
    console.error("Error in getting the markers by region", e);
    return null;
  }
};
export const UpdateCase = async (caseId, caseStatus, casePhotoUrl, navigate) => {
  const token = localStorage.getItem('webtoken');
  if (!token) {
    navigate("/login");
    throw new Error("No web token found in local storage");
  }
  try {
    const requestBody = { caseStatus, casePhotoUrl };

    const response = await fetch(`${DEFAULT_URL}case/${caseId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        navigate('/login');
        return;
      }
      throw new Error('Failed to update case');
    }

    return await response.json();

  } catch (error) {
    console.error("Error in UpdateCase function:", error);
    throw error;
  }
};

// export const UpdateCase = async (caseId, caseStatus, casePhotoUrl,navigate) => {
//   const token = localStorage.getItem('webtoken');
//   if (!token) {
//     window.location.reload();
//     navigate("/login");
 
//     throw new Error("No web token found in local storage");
//   }
//   try {
//     const requestBody = {
//       caseStatus,
//       casePhotoUrl  
//     };

//     const response = await fetch(`${DEFAULT_URL}case/${caseId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//       body: JSON.stringify(requestBody)
//     });

//     if (!response.ok) {
//       if (response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//         navigate('/login');
//         return;
//       }

//       throw new Error('Failed to fetch update case function');
//     }
//     const resultData = await response.json();
//     return resultData;

//   } catch (error) {
//     console.error("Error in UpdateCase function:", error);
//     throw error;
//   }
// };

export const AllTripByAdmin = async (navigate) => {
  const token = localStorage.getItem('webtoken');
  if (!token) {
    window.location.reload();
    navigate("/login");
 
    throw new Error("No web token found in local storage");
  }
  try {
    const TripList = await fetch(DEFAULT_URL + "users/all-trips", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!TripList.ok) {
      if (TripList.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch trip details');
    }

    const resultData = await TripList.json();

    return resultData;
  } catch (e) {
    console.log("Error in getting Trip details", e);
    return null;
  }
};
export const GetTripByIDAPI = async (tripId,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(`${DEFAULT_URL}users/admin/trip/${tripId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch trip by id');
    }
    const responseData = await response.json();
   

    return responseData; 
  } catch (error) {
    console.error('Error fetching marker', error);
    throw error; 
  }
};

export const GetCaseHistory = async (caseId,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(`${DEFAULT_URL}case/history/${caseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch case history api');
    }

    const responseData = await response.json();
   

    return responseData; 
  } catch (error) {
    console.error('Error getting case history', error);
    throw error; 
  }
};

export const GetNotification = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(DEFAULT_URL+ "notifications/unread-count", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch get notification');
    }
    const responseData = await response.json();
   

    return responseData; 
  } catch (error) {
    console.error('Error getting notification', error);
    throw error; 
  }
};
export const GetReadNotification = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    
   
    if (!token) {
        window.location.reload();
        navigate("/login");
    
      throw new Error('Token not found. Redirecting to login.');
    }

    const response = await fetch(`${DEFAULT_URL}notifications/mark-read`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }
      const errorMessage = `Error: ${response.status} - ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const responseData = await response.json();
   

    return responseData; 
  } catch (error) {
    console.error('Error getting read count', error);
    throw error; 
  }
};


export const GetAllRegionAPI = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(DEFAULT_URL+ "region", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch regions');
    }

    const responseData = await response.json();
  

    return responseData; 
  } catch (error) {
    console.error('Error getting in all region', error);
    throw error; 
  }
};

export const GetMissingMarkers = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(DEFAULT_URL+ "marker/unread", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch regions');
    }

    const responseData = await response.json();
   

    return responseData; 
  } catch (error) {
    console.error('Error getting in missing marker count', error);
    throw error; 
  }
};
export const GetTripReport = async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
      throw new Error("No web token found in local storage");
    }

    const response = await fetch(DEFAULT_URL + "users/trips/report", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }
      throw new Error('Failed to fetch trip report');
    }

    const blob = await response.blob(); 
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'trip_report.html');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link); 

  } catch (error) {
    console.error('Error getting trip report:', error);
    throw error;
  }
};


export const MissingMarkerMap= async (navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(DEFAULT_URL+ "marker/unread", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch missing markermap');
    }

    const responseData = await response.json();
  

    return responseData; 
  } catch (error) {
    console.error('Error getting in missing markermap', error);
    throw error; 
  }
};
export const CaseByTripId= async (tripId,navigate) => {
  try {
    const token = localStorage.getItem('webtoken');
    if (!token) {
      window.location.reload();
      navigate("/login");
   
      throw new Error("No web token found in local storage");
    }
    const response = await fetch(`${DEFAULT_URL}case/trip/${tripId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate('/login');
        return;
      }

      throw new Error('Failed to fetch missing markermap');
    }

    const responseData = await response.json();
    // console.error('casebytripid', responseData);

    return responseData; 
  } catch (error) {
    console.error('Error getting in missing markermap', error);
    throw error; 
  }
};