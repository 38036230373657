// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import '../../Styles/trippage.css';
// import { AllTripByAdmin } from '../../Services/APIManager';
// import GailMarker from '../../Assets/gailmarker.jpg';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { FaSearch,FaDownload } from 'react-icons/fa';
// const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

// const convertImageToBase64 = (imagePath) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => resolve(reader.result);
//         reader.onerror = reject;
//         fetch(imagePath)
//             .then(response => response.blob())
//             .then(blob => reader.readAsDataURL(blob))
//             .catch(reject);
//     });
// };

// export default function TripPage() {
//     const [trips, setTrips] = useState([]);
//     const [filteredTrips, setFilteredTrips] = useState([]);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [page, setPage] = useState(1);
//     const [loading, setLoading] = useState(false);
//     const [hasMore, setHasMore] = useState(true);
//     const observer = useRef();
//     const [base64Image, setBase64Image] = useState('');
//     const [missingMarkers, setMissingMarkers] = useState([]);

//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchTrips(1, 12);  // Set initial limit
//         convertImageToBase64(GailMarker).then(setBase64Image).catch(console.error);
//     }, []);


//     const handleDownloadClick = () => {
//         const htmlContent = generateHTML();
//         const blob = new Blob([htmlContent], { type: 'text/html' });
//         const url = URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = 'trip_report.html';
//         document.body.appendChild(a);
//         a.click();
//         document.body.removeChild(a);
//         URL.revokeObjectURL(url);
//     };

//     const fetchTrips = useCallback(async (page, limit) => {
//         setLoading(true);
//         const data = await AllTripByAdmin();  // Call updated API
//         if (data?.unauthorized) {
//             navigate('/login'); // Redirect to login page if unauthorized
//             return;
//         }
//         if (data && data.data) {
//             const fetchedTrips = data.data.map(trip => ({
//                 tripId: trip.tripId.toString(),
//                 riderName: trip.User?.userName,
//                 startTime: trip.startTime ? new Date(trip.startTime).toLocaleString('en-US', {
//                     year: 'numeric',
//                     month: '2-digit',
//                     day: '2-digit',
//                     hour: '2-digit',
//                     minute: '2-digit',
//                     second: '2-digit',
//                     timeZone: 'UTC'
//                 }) : 'N/A',

//                 endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
//                     year: 'numeric',
//                     month: '2-digit',
//                     day: '2-digit',
//                     hour: '2-digit',
//                     minute: '2-digit',
//                     second: '2-digit',
//                     timeZone: 'UTC'
//                 }) : 'Not yet ended',

//                 markersRead: trip.readMarkers,
//                 markersUnread: trip.unreadMarkers,
//                 totalMarkers: trip.totalMarkers,
//                 photos: []
//             }));

//             setTrips(prevTrips => {
//                 const newTrips = fetchedTrips.filter(trip =>
//                     !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
//                 );
//                 return [...prevTrips, ...newTrips];
//             });
//             setFilteredTrips(prevTrips => {
//                 const newTrips = fetchedTrips.filter(trip =>
//                     !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
//                 );
//                 return [...prevTrips, ...newTrips];
//             });

//             if (fetchedTrips.length < limit) {
//                 setHasMore(false);
//             }
//         } else {
//             console.error('Failed to fetch trips');
//         }
//         setLoading(false);
//     }, [navigate]);

//     const handleSearchChange = (e) => {
//         setSearchTerm(e.target.value);
//     };

//     const handleSearchClick = async () => {
//         if (!searchTerm.trim()) {
//             setFilteredTrips(trips); // Reset to show all trips if the search term is empty
//             return;
//         }

//         setLoading(true); // Show loading state while fetching

//         try {
//             const data = await AllTripByAdmin();
//             if (data?.unauthorized) {
//                 navigate('/login'); // Redirect to login page if unauthorized
//                 return;
//             }

//             if (data && data.data) {
//                 const fetchedTrips = data.data.map(trip => ({
//                     tripId: trip.tripId.toString(),
//                     riderName: trip.User?.userName,
//                     startTime: trip.startTime ? new Date(trip.startTime).toLocaleString('en-US', {
//                         timeZone: 'UTC',
//                         hour: 'numeric',
//                         minute: 'numeric',
//                         second: 'numeric',
//                         hour12: true,
//                         year: 'numeric',
//                         month: 'numeric',
//                         day: 'numeric'
//                     }) : 'N/A',
//                     endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
//                         timeZone: 'UTC',
//                         hour: 'numeric',
//                         minute: 'numeric',
//                         second: 'numeric',
//                         hour12: true,
//                         year: 'numeric',
//                         month: 'numeric',
//                         day: 'numeric'
//                     }) : 'Not yet ended',
//                     markersRead: trip.readMarkers,
//                     markersUnread: trip.unreadMarkers,
//                     totalMarkers: trip.totalMarkers
//                 }));

//                 // Filter trips based on the search term
//                 const filteredTrips = fetchedTrips.filter(trip =>
//                     trip.tripId.toLowerCase() === searchTerm.toLowerCase()
//                 );

//                 setFilteredTrips(filteredTrips); // Update state with filtered trips
//             } else {
//                 console.error('Failed to fetch trips');
//             }
//         } catch (error) {
//             console.error('Error fetching trips:', error);
//         } finally {
//             setLoading(false); // Hide loading state
//         }
//     };
//     const handlePhotoClick = (tripId) => {
//         navigate(`/trip/${tripId}/photos`);
//     };

//     const handleMapClick = (tripId) => {
//         navigate(`/tripmap/${tripId}`);
//     };

//     const lastTripElementRef = useRef(null);

//     useEffect(() => {
//         const options = {
//             root: null,
//             rootMargin: '0px',
//             threshold: 1.0
//         };

//         observer.current = new IntersectionObserver((entries) => {
//             if (entries[0].isIntersecting && !loading && hasMore) {
//                 setPage(prevPage => prevPage + 1);
//             }
//         }, options);

//         if (lastTripElementRef.current) {
//             observer.current.observe(lastTripElementRef.current);
//         }

//         return () => {
//             if (observer.current && lastTripElementRef.current) {
//                 observer.current.unobserve(lastTripElementRef.current);
//             }
//         };
//     }, [lastTripElementRef.current, loading, hasMore]);

//     useEffect(() => {
//         if (page > 1) {
//             fetchTrips(page, 12);
//         }
//     }, [page, fetchTrips]);

//     const generateHTML = () => {
//         const title = 'GAIL Marker Surveillance System';
//         const subtitle = 'Report on marker monitoring trips conducted in the DLW region (Varanasi), during July 2024';
//         const introduction = `This pilot project is deployed in the DLW region of Varanasi city, UP. All the pipeline markers, (metal and cement) have been tagged using RFID technology, and their data is stored on the cloud servers along with their geolocation coordinates. Monitoring staff (aka riders) have a handheld RFID-enabled mobile device that they use to monitor the presence of these markers by riding across the entire DLW region on their two-wheelers.
//         The following table details the trips that were conducted during the month of July 2024.`;
    
//         const tripRows = trips.map(trip => `
//             <tr>
//                 <td>${trip.tripId}</td>
//                 <td>${trip.riderName}</td>
//                 <td>${trip.startTime}</td>
//                 <td>${trip.endTime}</td>
//                 <td>${trip.markersRead}</td>
//                 <td>${trip.markersUnread}</td>
//                 <td>${trip.totalMarkers}</td>
//             </tr>
//         `).join('');
    
//         const missingMarkerRows = missingMarkers.map(marker => `
//             <tr>
//                 <td>${marker.markerId}</td>
//                 <td>${marker.regionName}</td>
//                 <td>${marker.latitude}</td>
//                 <td>${marker.longitude}</td>
//             </tr>
//         `).join('');
    
//         const htmlContent = `
//             <html>
//             <head>
//                 <style>
//                     body {
//                         font-family: Arial, sans-serif;
//                         margin: 0;
//                         padding: 0;
//                     }
//                     h1, h2, h3 {
//                         text-align: center;
//                     }
//                     img {
//                         width: 400px;
//                         height: 250px;
//                         border: 5px solid black;
//                         display: block;
//                         margin: 0 auto;
//                     }
//                     table {
//                         width: 100%;
//                         border-collapse: collapse;
//                         margin: 20px auto; /* Added margin to center and separate tables */
//                     }
//                     th, td {
//                         border: 1px solid #f2aa84;
//                         padding: 8px;
//                         text-align: left;
//                     }
//                     th {
//                         background-color: #f2aa84;
//                         color: #fff;
//                     }
//                     .missing-markers {
//                         margin: 30px; /* Added margin around missing markers section */
//                     }
//                 </style>
//             </head>
//               <div class="missing-markers">
//             <body>
//                 <h1>${title}</h1>
//                 <h2>${subtitle}</h2>
//                 <img src="${base64Image}" alt="GAIL Marker" />
//                 <h3>Introduction</h3>
//                 <p>${introduction}</p>
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>Trip ID</th>
//                             <th>Rider Name</th>
//                             <th>Start Time</th>
//                             <th>End Time</th>
//                             <th>Markers Read</th>
//                             <th>Markers Unread</th>
//                             <th>Total Markers</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         ${tripRows}
//                     </tbody>
//                 </table>
               
//                     <h3>Missing Markers</h3>
//                     <table>
//                         <thead>
//                             <tr>
//                                 <th>Marker ID</th>
//                                 <th>Region Name</th>
//                                 <th>Latitude</th>
//                                 <th>Longitude</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             ${missingMarkerRows}
//                         </tbody>
//                     </table>
               
//                 <p style="text-align: center;">Prepared by BQTech Pvt. Ltd. (IOT-RFID Division of Binary Systems)</p>
//             </body>
//               </div>
//             </html>
//         `;
//         return htmlContent;
//     };
    

//     const downloadHTML = () => {
//         const element = document.createElement('a');
//         const file = new Blob([generateHTML()], { type: 'text/html' });
//         element.href = URL.createObjectURL(file);
//         element.download = 'trip-report.html';
//         document.body.appendChild(element);
//         element.click();
//         document.body.removeChild(element);
//     };
//     return (
//         <HelmetProvider>
//         <div className='trip-page'>
//             <Helmet>
//                 <title>Trip Management - GAIL Marker Surveillance System</title>
//                 <meta name="description" content="Manage and monitor trips for the GAIL Marker Surveillance System." />
//                 <meta name="keywords" content="GAIL, marker surveillance, trips, monitoring, RFID" />
//             </Helmet>
//             <h3>Trips</h3>
//             <div className='search-container'>
//             <input
//                     type="text"
//                     placeholder="Search by Trip ID..."
//                     value={searchTerm}
//                     onChange={handleSearchChange}
//                     onKeyPress={(e) => {
//                         if (e.key === 'Enter') {
//                             handleSearchClick();
//                         }
//                     }}
//                 />
//                 <button onClick={handleSearchClick}><FaSearch /></button>
//                 <button onClick={handleDownloadClick}><FaDownload /></button>
                
//             </div>
//             <div className='trip-container'>
//                 {filteredTrips.map((trip, index) => (
//                     <TripCard key={index} trip={trip} handlePhotoClick={handlePhotoClick} handleMapClick={handleMapClick} />
//                 ))}
//                 <div ref={lastTripElementRef}></div>
//             </div>
//             {/* {loading && hasMore && <p>Loading...</p>} */}
//         </div>
//            </HelmetProvider>
//     );
// }
// function TripCard({ trip, handlePhotoClick, handleMapClick }) {
//     const [ref, inView] = useInView({ triggerOnce: true });


//     return (
//         <motion.div
//             ref={ref}
//             className='trip-card'
//             initial={{ opacity: 0, y: 50 }}
//             animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
//             transition={transition}
//             onClick={() => handleMapClick(trip.tripId)}
//         >
//             <h4>Trip ID: {trip.tripId}</h4>
//             <p><span>Rider Name:</span> {trip.riderName}</p>
//             <p><span>Region Name:</span> {trip.regionName}</p>
//             <p><span>Start Time: </span>{trip.startTime}</p>
//             <p><span>End Time:</span> {trip.endTime}</p>
//             <p><span>Markers Read:</span> {trip.markersRead}</p>
//             <p><span>Markers Unread:</span> {trip.markersUnread}</p>
//             <p><span>Total Markers:</span> {trip.totalMarkers}</p>
//         </motion.div>
//     );
// }
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../../Styles/trippage.css';
import { AllTripByAdmin } from '../../Services/APIManager';
import GailMarker from '../../Assets/gailmarker.jpg';
import { GetTripReport } from '../../Services/APIManager';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaSearch,FaDownload } from 'react-icons/fa';
const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

const convertImageToBase64 = (imagePath) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        fetch(imagePath)
            .then(response => response.blob())
            .then(blob => reader.readAsDataURL(blob))
            .catch(reject);
    });
};

export default function TripPage() {
    const [trips, setTrips] = useState([]);
    const [filteredTrips, setFilteredTrips] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [base64Image, setBase64Image] = useState('');
    const [missingMarkers, setMissingMarkers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchTrips(1, 12);  // Set initial limit
        convertImageToBase64(GailMarker).then(setBase64Image).catch(console.error);
    }, []);

    const handleDownloadClick = async () => {
        try {
          await GetTripReport(navigate); // Call the GetTripReport function to download the report
        } catch (error) {
          console.error('Error downloading trip report:', error);
        }
      };
    // const handleDownloadClick = () => {
    //     const htmlContent = generateHTML();
    //     const blob = new Blob([htmlContent], { type: 'text/html' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'trip_report.html';
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     URL.revokeObjectURL(url);
    // };

    const fetchTrips = useCallback(async (page, limit) => {
        setLoading(true);
        const data = await AllTripByAdmin();  // Call updated API
        if (data?.unauthorized) {
            navigate('/login'); // Redirect to login page if unauthorized
            return;
        }
        if (data && data.data) {
            const fetchedTrips = data.data.map(trip => ({
                tripId: trip.tripId.toString(),
                riderName: trip.User?.userName,
                startTime: trip.startTime ? new Date(trip.startTime).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: 'UTC'
                }) : 'N/A',

                endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: 'UTC'
                }) : 'Not yet ended',

                markersRead: trip.readMarkers,
                markersUnread: trip.unreadMarkers,
                totalMarkers: trip.totalMarkers,
                photos: []
            }));

            setTrips(prevTrips => {
                const newTrips = fetchedTrips.filter(trip =>
                    !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
                );
                return [...prevTrips, ...newTrips];
            });
            setFilteredTrips(prevTrips => {
                const newTrips = fetchedTrips.filter(trip =>
                    !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
                );
                return [...prevTrips, ...newTrips];
            });

            if (fetchedTrips.length < limit) {
                setHasMore(false);
            }
        } else {
            console.error('Failed to fetch trips');
        }
        setLoading(false);
    }, [navigate]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchClick = async () => {
        if (!searchTerm.trim()) {
            setFilteredTrips(trips); // Reset to show all trips if the search term is empty
            return;
        }

        setLoading(true); // Show loading state while fetching

        try {
            const data = await AllTripByAdmin();
            if (data?.unauthorized) {
                navigate('/login'); // Redirect to login page if unauthorized
                return;
            }

            if (data && data.data) {
                const fetchedTrips = data.data.map(trip => ({
                    tripId: trip.tripId.toString(),
                    riderName: trip.User?.userName,
                    startTime: trip.startTime ? new Date(trip.startTime).toLocaleString('en-US', {
                        timeZone: 'UTC',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    }) : 'N/A',
                    endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
                        timeZone: 'UTC',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    }) : 'Not yet ended',
                    markersRead: trip.readMarkers,
                    markersUnread: trip.unreadMarkers,
                    totalMarkers: trip.totalMarkers
                }));

                // Filter trips based on the search term
                const filteredTrips = fetchedTrips.filter(trip =>
                    trip.tripId.toLowerCase() === searchTerm.toLowerCase()
                );

                setFilteredTrips(filteredTrips); // Update state with filtered trips
            } else {
                console.error('Failed to fetch trips');
            }
        } catch (error) {
            console.error('Error fetching trips:', error);
        } finally {
            setLoading(false); // Hide loading state
        }
    };
    const handlePhotoClick = (tripId) => {
        navigate(`/trip/${tripId}/photos`);
    };

    const handleMapClick = (tripId) => {
        navigate(`/tripmap/${tripId}`);
    };

    const lastTripElementRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !loading && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        }, options);

        if (lastTripElementRef.current) {
            observer.current.observe(lastTripElementRef.current);
        }

        return () => {
            if (observer.current && lastTripElementRef.current) {
                observer.current.unobserve(lastTripElementRef.current);
            }
        };
    }, [lastTripElementRef.current, loading, hasMore]);

    useEffect(() => {
        if (page > 1) {
            fetchTrips(page, 12);
        }
    }, [page, fetchTrips]);

   
    return (
        <HelmetProvider>
        <div className='trip-page'>
            <Helmet>
                <title>Trip Management - GAIL Marker Surveillance System</title>
                <meta name="description" content="Manage and monitor trips for the GAIL Marker Surveillance System." />
                <meta name="keywords" content="GAIL, marker surveillance, trips, monitoring, RFID" />
            </Helmet>
            <h3>Trips</h3>
            <div className='search-container'>
            <input
                    type="text"
                    placeholder="Search by Trip ID..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearchClick();
                        }
                    }}
                />
                <button onClick={handleSearchClick}><FaSearch /></button>
                <button onClick={handleDownloadClick}><FaDownload /></button>
                
            </div>
            <div className='trip-container'>
                {/* {filteredTrips.map((trip, index) => (
                    <TripCard key={index} trip={trip} handlePhotoClick={handlePhotoClick} handleMapClick={handleMapClick} />
                ))} */}
                {filteredTrips.length > 0 ? (
                        filteredTrips.map((trip, index) => (
                            <TripCard key={index} trip={trip} handlePhotoClick={handlePhotoClick} handleMapClick={handleMapClick} />
                        ))
                    ) : (
                        <p>There are no trips available.</p>
                    )}
                <div ref={lastTripElementRef}></div>
            </div>
            {/* {loading && hasMore && <p>Loading...</p>} */}
        </div>
           </HelmetProvider>
    );
}
function TripCard({ trip, handlePhotoClick, handleMapClick }) {
    const [ref, inView] = useInView({ triggerOnce: true });


    return (
        <motion.div
            ref={ref}
            className='trip-card'
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={transition}
            onClick={() => handleMapClick(trip.tripId)}
        >
            <h4>Trip ID: {trip.tripId}</h4>
            <p><span>Rider Name:</span> {trip.riderName}</p>
            <p><span>Region Name:</span> {trip.regionName}</p>
            <p><span>Start Time: </span>{trip.startTime}</p>
            <p><span>End Time:</span> {trip.endTime}</p>
            <p><span>Markers Read:</span> {trip.markersRead}</p>
            <p><span>Markers Unread:</span> {trip.markersUnread}</p>
            <p><span>Total Markers:</span> {trip.totalMarkers}</p>
        </motion.div>
    );
}

