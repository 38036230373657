// import React, { useState } from 'react';
// import '../../Styles/mappage.css';

// export default function PinImagesModal({ marker, handleClose }) {
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//   if (!marker || !marker.photos || marker.photos.length === 0) {
//     return null; 
//   }

//   const handlePrevImage = () => {
//     setSelectedImageIndex((prevIndex) => (prevIndex - 1 + marker.photos.length) % marker.photos.length);
//   };

//   const handleNextImage = () => {
//     setSelectedImageIndex((prevIndex) => (prevIndex + 1) % marker.photos.length);
//   };

//   return (
//     <div className="modal">
//       <div className="modal-content" onClick={handleClose}>
//         <button className='modal-close-button' onClick={handleClose}>
//           <i className="fa-solid fa-xmark"></i> Close
//         </button>
//         <h2>Region ID: {marker.regionId}</h2>
//         <div className='creation-date'>
//           <p><span>Created At:</span> {new Date(marker.createdAt).toLocaleDateString()}</p>
//           <div className='line-intersect'></div>
//           <p><span>Updated At:</span> {new Date(marker.updatedAt).toLocaleDateString()}</p>
//         </div>
//         <div className="carousel">
//           <button className='carousel-button left' onClick={handlePrevImage}>
//             <i className="fa-solid fa-chevron-left"></i>
//           </button>
//           <img 
//             src={marker.photos[selectedImageIndex].markerPhotoUrl} 
//             alt={`Photo ${selectedImageIndex + 1}`} 
//             className="carousel-image" 
//           />
//           <button className='carousel-button right' onClick={handleNextImage}>
//             <i className="fa-solid fa-chevron-right"></i>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'; // Import React Icons
import '../../Styles/mappage.css';

export default function PinImagesModal({ marker, handleClose }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  if (!marker || !marker.photos || marker.photos.length === 0) {
    return null; 
  }

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + marker.photos.length) % marker.photos.length);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % marker.photos.length);
  };

  return (
    <div className="modal">
      <div className="modal-content" onClick={handleClose}>
        <button className='modal-close-button' onClick={handleClose}>
          <FaTimes /> Close 
        </button>
        <h2>Region ID: {marker.regionId}</h2>
        <div className='creation-date'>
          <p><span>Created At:</span> {new Date(marker.createdAt).toLocaleDateString()}</p>
          <div className='line-intersect'></div>
          <p><span>Updated At:</span> {new Date(marker.updatedAt).toLocaleDateString()}</p>
        </div>
        <div className="carousel">
          <button className='carousel-button left' onClick={handlePrevImage}>
            <FaChevronLeft /> 
          </button>
          <img 
            src={marker.photos[selectedImageIndex].markerPhotoUrl} 
            alt={`Photo ${selectedImageIndex + 1}`} 
            className="carousel-image" 
          />
          <button className='carousel-button right' onClick={handleNextImage}>
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}
