// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import purplemap from '../../Assets/purple-removebg-preview.png'

// const purpleIcon = new L.Icon({
//   iconUrl:purplemap,
//   iconSize: [40, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowSize: [41, 41],
// });

// // Leaflet default marker icons
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

// const IssueMap = () => {
//   const location = useLocation(); 
  
//   const selectedLatitude = location.state?.latitude;
//   const selectedLongitude = location.state?.longitude;

//   const [mapCenter, setMapCenter] = useState(null); 
//   const [selectedCoordinates, setSelectedCoordinates] = useState(null);

//   useEffect(() => {
//     if (selectedLatitude && selectedLongitude) {
//       const latLng = [parseFloat(selectedLatitude), parseFloat(selectedLongitude)];
//       setMapCenter(latLng); 
//       setSelectedCoordinates(latLng); 
//     }
//   }, [selectedLatitude, selectedLongitude]);

//   return (
//     <div>
//       <h3>Issue Map</h3>
//       {mapCenter ? (
//         <MapContainer center={mapCenter} zoom={15} style={{ height: '420px', width: '100%',margin:'auto'}}>
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           />
//           {selectedCoordinates && (
//             <Marker
//               position={selectedCoordinates}
//               icon={purpleIcon}
//             >
//               <Popup>
//                 <div>
//                   <p>Latitude: {selectedCoordinates[0]}</p>
//                   <p>Longitude: {selectedCoordinates[1]}</p>
//                 </div>
//               </Popup>
//             </Marker>
//           )}
//         </MapContainer>
//       ) : (
//         <p>Loading map...</p>
//       )}
//     </div>
//   );
// };

// export default IssueMap;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet'; // Import Circle
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import purplemap from '../../Assets/purple-removebg-preview.png';

// Define the purple marker icon
const purpleIcon = new L.Icon({
  iconUrl: purplemap,
  iconSize: [40, 41],
  iconAnchor: [20, 41], 
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// Leaflet default marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const IssueMap = () => {
  const location = useLocation();
  const selectedCase = location.state?.caseId;
  const selectedLatitude = location.state?.latitude;
  const selectedLongitude = location.state?.longitude;

  const [mapCenter, setMapCenter] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);

  useEffect(() => {
    if (selectedLatitude && selectedLongitude) {
      const latLng = [parseFloat(selectedLatitude), parseFloat(selectedLongitude)];
      setMapCenter(latLng);
      setSelectedCoordinates(latLng);
    }
  }, [selectedLatitude, selectedLongitude]);

  return (
    <div className='issue-map'>
   <h3>{`Issue Map - Case ${selectedCase}`}</h3>

      {mapCenter ? (
        <MapContainer center={mapCenter} zoom={17} style={{ height: '500px', width: '100%', margin: 'auto' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {selectedCoordinates && (
            <>
          
              <Circle
                center={selectedCoordinates}
                radius={10} 
                pathOptions={{ color: 'orange', fillColor: 'orange', fillOpacity: 0.3 }} 
              />
              <Marker position={selectedCoordinates} icon={purpleIcon}>
                <Popup>
                  <div>
                    <p>Latitude: {selectedCoordinates[0]}</p>
                    <p>Longitude: {selectedCoordinates[1]}</p>
                  </div>
                </Popup>
              </Marker>
            </>
          )}
        </MapContainer>
      ) : (
        <p>Loading map...</p>
      )}
    </div>
  );
};

export default IssueMap;
