const config = {
    // BASE_URL:" https://gailapi.binarysystems.in/api/v1/",
    // GAIL_URL:"https://gailapi.binarysystems.in/api/v1/"
    // BASE_URL:" https://84d7-45-119-8-238.ngrok-free.app/api/v1/",
    // GAIL_URL:"https://84d7-45-119-8-238.ngrok-free.app/api/v1/"
    BASE_URL:"https://gailapi.binarysystems.in/api/v1/"
    
}

export default config

