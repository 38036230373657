// import React, { useEffect, useState } from 'react';
// import { MapContainer, TileLayer, Marker, Popup, CircleMarker, useMap } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import '../../Styles/livelocation.css';
// import User from '../../Assets/Riders.png';
// import Redpin from '../../Assets/gps.png';
// import io from 'socket.io-client';
// import { fetchAllMarkers, AllCaseAPI } from '../../Services/APIManager';

// const riderIcon = new L.Icon({
//   iconUrl: User,
//   iconSize: [80, 80],
//   iconAnchor: [16, 32],
//   popupAnchor: [0, -32]
// });

// const redCaseIcon = new L.Icon({
//   iconUrl: Redpin,
//   iconSize: [35, 35], 
//   iconAnchor: [16, 32],
//   popupAnchor: [0, -32]
// });

// const markerIcon = new L.Icon({
//   iconUrl: Redpin,
//   iconSize: [35, 35], 
//   iconAnchor: [16, 32],
//   popupAnchor: [0, -32]
// });

// const CenterMap = ({ positions }) => {
//   const map = useMap();
//   const [mapCentered, setMapCentered] = useState(false);

//   useEffect(() => {
//     if (positions.length > 0 && !mapCentered) {
//       const bounds = L.latLngBounds(positions);
//       map.fitBounds(bounds);
//       setMapCentered(true); // Only center the map once
//     }
//   }, [positions, map, mapCentered]);

//   return null;
// };


// const LiveLocationMap = ({ navigate }) => {
//   const [locations, setLocations] = useState([]);
//   const [unreadMarkers, setUnreadMarkers] = useState([]);
//   const [caseData, setCaseData] = useState([]);
//   const [error, setError] = useState(null);
//   const [nonJsonMessages, setNonJsonMessages] = useState([]);
//   const [missingMarkersCount, setMissingMarkersCount] = useState(0);
//   const [caseMarkersCount, setCaseMarkersCount] = useState(0);

//   useEffect(() => {
//     const fetchUnreadMarkers = async () => {
//       try {
//         const responseData = await fetchAllMarkers(navigate);
//         const markers = responseData.data || [];
//         setUnreadMarkers(Array.isArray(markers) ? markers : []);
//         setMissingMarkersCount(markers.length); // Set the count of missing markers
//       } catch (err) {
//         console.error('Error fetching unread markers:', err);
//         setError('Failed to load unread markers.');
//       }
//     };

//     const fetchCaseData = async () => {
//       try {
//         const caseResponse = await AllCaseAPI(navigate);
//         setCaseData(caseResponse.data || []);
//         setCaseMarkersCount(caseResponse.data.length); // Set the count of case markers
//       } catch (err) {
//         console.error('Error fetching case data:', err);
//         setError('Failed to load case data.');
//       }
//     };

//     fetchUnreadMarkers();
//     fetchCaseData();

//     const socket = io('https://gailapi.binarysystems.in/');
//     socket.on('connect', () => console.log('Connected to socket.io', socket.id));

//     const updateTimeLimit = 30000; // 30 seconds

//     socket.on('admin:notifyRiderLocation', (data) => {
//       console.log('Rider location update received: ', data);
//       try {
//         const { latitude, longitude, userId } = data;
//         if (latitude && longitude && userId > 0) {
//           setLocations((prevLocations) => [
//             ...prevLocations.filter(location => location.userId !== userId),
//             { userId, latitude: parseFloat(latitude), longitude: parseFloat(longitude), lastUpdated: Date.now() }
//           ]);
//           setError(null);
//         } else {
//           setError('Invalid location data or userId <= 0.');
//         }
//       } catch (error) {
//         console.error('Failed to process location data', error);
//         setError('Failed to process location data.');
//       }
//     });

//     // Remove riders who haven't sent a location update in the specified time
//     const intervalId = setInterval(() => {
//       const now = Date.now();
//       setLocations(prevLocations => prevLocations.filter(location => now - location.lastUpdated < updateTimeLimit));
//     }, 10000); // Check every 10 seconds

//     socket.on('nonJsonMessage', (message) => {
//       setNonJsonMessages((prevMessages) => [...prevMessages, message]);
//     });

//     return () => {
//       clearInterval(intervalId);
//       socket.disconnect();
//     };
//   }, [navigate]);

//   const allPositions = [
//     ...locations.map(loc => [loc.latitude, loc.longitude]),
//     ...unreadMarkers.map(marker => [parseFloat(marker.latitude), parseFloat(marker.longitude)]),
//     ...caseData.map(caseItem => [parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)])
//   ];

//   return (
//     <div className="live-location-map">
//    <div className="marker-container">
//   {/* <h3>Markers Overview</h3> */}
//   <div className='map-icon-detail'>
//     <div className='marker-legends'>
//       <div className='legend-items'>
//         <span ><img src={Redpin} alt="Marker Category 1" className='pin-image'/></span> {/* Red marker for missing */}
//         <span>Missing Markers: {unreadMarkers.length}</span>
//       </div>
//       <div className='legend-item'>
//         <span className='circle-icon orange'></span> 
//         <span>Issue Markers: {caseData.length}</span>
//       </div>
//     </div>
//   </div>
// </div>



//       <MapContainer center={[25.3176, 82.9739]} zoom={15} style={{ height: '100vh', width: '100%' }}>
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />

//         <CenterMap positions={allPositions} />

//         {locations.map((user) => (
//           <Marker
//             key={user.userId}
//             position={[user.latitude, user.longitude]}
//             icon={riderIcon}
//           >
//             <Popup>
//               <strong>Rider ID: {user.userId}</strong><br />
//               Latitude: {user.latitude.toFixed(4)}<br />
//               Longitude: {user.longitude.toFixed(4)}
//             </Popup>
//           </Marker>
//         ))}

//         {unreadMarkers.map((marker) => {
//           const isCaseMarker = caseData.some((caseItem) =>
//             caseItem.markerId === marker.markerId
//           );

//           return (
//             <React.Fragment key={marker.markerId}>
//               {isCaseMarker && (
//                 <CircleMarker
//                   center={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                   color="orange"
//                   radius={20}
//                 />
//               )}
//               <Marker
//                 position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                 icon={isCaseMarker ? redCaseIcon : markerIcon}
//               >
//                 <Popup>
//                   <strong>Marker ID: {marker.markerId}</strong><br />
//                   Category: {marker.markerCategoryId}<br />
//                   Region: {marker.Region ? marker.Region.regionName : 'N/A'}<br />
//                   Latitude: {parseFloat(marker.latitude).toFixed(4)}<br />
//                   Longitude: {parseFloat(marker.longitude).toFixed(4)}
//                 </Popup>
//               </Marker>
//             </React.Fragment>
//           );
//         })}

//         {caseData.map((caseItem) => {
//           const isUnreadMarker = unreadMarkers.some(
//             (marker) => marker.markerId === caseItem.markerId
//           );

//           if (!isUnreadMarker) {
//             return (
//               <React.Fragment key={caseItem.caseId}>
//                 <CircleMarker
//                   center={[parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)]}
//                   color="orange"
//                   radius={20}
//                 />
//                 <Marker
//                   position={[parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)]}
//                   icon={redCaseIcon}>
//                   <Popup>
//                     <strong>Marker ID: {caseItem.markerId}</strong><br />
//                     Category: {caseItem.caseType ? caseItem.caseType.caseName : 'N/A'}<br />
//                     Latitude: {parseFloat(caseItem.latitude).toFixed(4)}<br />
//                     Longitude: {parseFloat(caseItem.longitude).toFixed(4)}
//                   </Popup>
//                 </Marker>
//               </React.Fragment>
//             );
//           }
//           return null;
//         })}
//       </MapContainer>

//       <div className="non-json-messages">
//         <h2>Non-JSON Messages:</h2>
//         <ul>
//           {nonJsonMessages.map((message, index) => (
//             <li key={index}>{message}</li>
//           ))}
//         </ul>
//       </div>

//       {error && <div className="error">{error}</div>}
//     </div>
//   );
// };

// export default LiveLocationMap;
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, CircleMarker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../../Styles/livelocation.css';
import User from '../../Assets/Riders.png';
import Redpin from '../../Assets/gps.png';
import io from 'socket.io-client';
import { fetchAllMarkers, AllCaseAPI } from '../../Services/APIManager';

const riderIcon = new L.Icon({
  iconUrl: User,
  iconSize: [80, 80],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

const redCaseIcon = new L.Icon({
  iconUrl: Redpin,
  iconSize: [35, 35], 
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

const markerIcon = new L.Icon({
  iconUrl: Redpin,
  iconSize: [35, 35], 
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

const CenterMap = ({ positions }) => {
  const map = useMap();
  const [mapCentered, setMapCentered] = useState(false);

  useEffect(() => {
    if (positions.length > 0 && !mapCentered) {
      const bounds = L.latLngBounds(positions);
      map.fitBounds(bounds);
      setMapCentered(true); 
    }
  }, [positions, map, mapCentered]);

  return null;
};

const LiveLocationMap = ({ navigate }) => {
  const [locations, setLocations] = useState([]);
  const [unreadMarkers, setUnreadMarkers] = useState([]);
  const [caseData, setCaseData] = useState([]);
  const [error, setError] = useState(null);
  const [nonJsonMessages, setNonJsonMessages] = useState([]);
  const [missingMarkersCount, setMissingMarkersCount] = useState(0);
  const [caseMarkersCount, setCaseMarkersCount] = useState(0);

  useEffect(() => {
    const fetchUnreadMarkers = async () => {
      try {
        const responseData = await fetchAllMarkers(navigate);
        const markers = responseData.data || [];
        const missingMarkers = markers.filter(marker => marker.markerStatus === 'missing');
        
        setUnreadMarkers(missingMarkers);
        setMissingMarkersCount(missingMarkers.length);
      } catch (err) {
        console.error('Error fetching unread markers:', err);
        setError('Failed to load unread markers.');
      }
    };
  
    const fetchCaseData = async () => {
      try {
        const caseResponse = await AllCaseAPI(navigate);
        setCaseData(caseResponse.data || []);
        setCaseMarkersCount(caseResponse.data.length);
      } catch (err) {
        console.error('Error fetching case data:', err);
        setError('Failed to load case data.');
      }
    };
  
    fetchUnreadMarkers();
    fetchCaseData();
      const socket = io('https://gailapi.binarysystems.in/');
    socket.on('connect', () => console.log('Connected to socket.io', socket.id));

    const updateTimeLimit = 30000; // 30 seconds

    socket.on('admin:notifyRiderLocation', (data) => {
      console.log('Rider location update received: ', data);
      try {
        const { latitude, longitude, userId } = data;
        if (latitude && longitude && userId > 0) {
          setLocations((prevLocations) => [
            ...prevLocations.filter(location => location.userId !== userId),
            { userId, latitude: parseFloat(latitude), longitude: parseFloat(longitude), lastUpdated: Date.now() }
          ]);
          setError(null);
        } else {
          setError('Invalid location data or userId <= 0.');
        }
      } catch (error) {
        console.error('Failed to process location data', error);
        setError('Failed to process location data.');
      }
    });

    // Remove riders who haven't sent a location update in the specified time
    const intervalId = setInterval(() => {
      const now = Date.now();
      setLocations(prevLocations => prevLocations.filter(location => now - location.lastUpdated < updateTimeLimit));
    }, 10000); // Check every 10 seconds

    socket.on('nonJsonMessage', (message) => {
      setNonJsonMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      clearInterval(intervalId);
      socket.disconnect();
    };
 
  
  }, [navigate]);
  

  const allPositions = [
    ...locations.map(loc => [loc.latitude, loc.longitude]),
    ...unreadMarkers.map(marker => [parseFloat(marker.latitude), parseFloat(marker.longitude)]),
    ...caseData.map(caseItem => [parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)])
  ];

  return (
    <div className="live-location-map">
      <div className="marker-container">
        <div className='map-icon-detail'>
          <div className='marker-legends'>
            <div className='legend-items'>
              <span><img src={Redpin} alt="Marker Category 1" className='pin-image'/></span>
              <span>Missing Markers: {unreadMarkers.length}</span>
            </div>
            <div className='legend-item'>
              <span className='circle-icon orange'></span> 
              <span>Issue Markers: {caseData.length}</span>
            </div>
          </div>
        </div>
      </div>

      <MapContainer center={[25.3176, 82.9739]} zoom={15} style={{ height: '80vh' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        <CenterMap positions={allPositions} />

        {locations.map((user) => (
          <Marker
            key={user.userId}
            position={[user.latitude, user.longitude]}
            icon={riderIcon}
          >
            <Popup>
              <strong>Rider ID: {user.userId}</strong><br />
              Latitude: {user.latitude.toFixed(4)}<br />
              Longitude: {user.longitude.toFixed(4)}
            </Popup>
          </Marker>
        ))}

        {unreadMarkers.map((marker) => {
          const isCaseMarker = caseData.some((caseItem) => caseItem.markerId === marker.markerId);

          return (
            <React.Fragment key={marker.markerId}>
              {isCaseMarker && (
                <CircleMarker
                  center={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                  color="orange"
                  radius={20}
                />
              )}
              <Marker
                position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                icon={isCaseMarker ? redCaseIcon : markerIcon}
              >
                <Popup>
                  <strong>Marker ID: {marker.markerId}</strong><br />
                  Category: {marker.markerCategoryId}<br />
                  Region: {marker.Region ? marker.Region.regionName : 'N/A'}<br />
                  Latitude: {parseFloat(marker.latitude).toFixed(4)}<br />
                  Longitude: {parseFloat(marker.longitude).toFixed(4)}
                </Popup>
              </Marker>
            </React.Fragment>
          );
        })}

        {caseData.map((caseItem) => {
          const isUnreadMarker = unreadMarkers.some(
            (marker) => marker.markerId === caseItem.markerId
          );

          if (!isUnreadMarker) {
            return (
              <React.Fragment key={caseItem.caseId}>
                <CircleMarker
                  center={[parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)]}
                  color="orange"
                  radius={20}
                />
                <Marker
                  position={[parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)]}
                  icon={redCaseIcon}>
                  <Popup>
                    <strong>Marker ID: {caseItem.markerId}</strong><br />
                    Category: {caseItem.caseType ? caseItem.caseType.caseName : 'N/A'}<br />
                    Latitude: {parseFloat(caseItem.latitude).toFixed(4)}<br />
                    Longitude: {parseFloat(caseItem.longitude).toFixed(4)}
                  </Popup>
                </Marker>
              </React.Fragment>
            );
          }
          return null;
        })}
      </MapContainer>

      <div className="non-json-messages">
        <h2>Non-JSON Messages:</h2>
        <ul>
          {nonJsonMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>

      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default LiveLocationMap;