import React, { useState, useEffect } from 'react';
import '../../Styles/alerts.css';
import { AllCaseAPI, UpdateCase } from '../../Services/APIManager';
import { useNavigate } from 'react-router-dom';

const IssuePage = () => {
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [issueId, setIssueId] = useState('');
  const [date, setDate] = useState('');
  const [username, setUsername] = useState('');
  const [userIdFilter, setUserIdFilter] = useState('');
  const [status, setStatus] = useState('all');
  const [filteredIssues, setFilteredIssues] = useState(null);
  const [issueToUpdate, setIssueToUpdate] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const caseData = await AllCaseAPI();
        const formattedIssues = caseData.data.map(item => {
          const casePhoto = item.casePhotos && item.casePhotos.length > 0 ? item.casePhotos[0].casePhotoUrl : 'https://defaultimage.url/default.png';
          return {
            id: item.caseId,
            userId: item.userId,
            tripId: item.tripId,
            description: `Case related to marker ${item.markerId || 'Unknown'}`,
            image: casePhoto,
            createdAt: item.createdAt ? new Date(item.createdAt).toLocaleString('en-US', {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC'
            }) : 'N/A',
            updatedAt: item.updatedAt ? new Date(item.updatedAt).toLocaleString('en-US', {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC'
            }) : 'N/A',
            deletedAt: item.deletedAt ? new Date(item.deletedAt).toLocaleString('en-US', {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC'
            }) : null,
            status: item.caseStatus.toLowerCase(),
            casePhotoId: item.casePhotos && item.casePhotos.length > 0 ? item.casePhotos[0].casePhotoId : null,
          };
        });
        setIssues(formattedIssues);
      } catch (error) {
        console.error('Error fetching case data:', error);
      }
    };
    fetchData();
  }, []);

  const handleStatusUpdate = (id, action) => {
    setIsConfirmModalOpen(true);
    setIssueToUpdate(id);
    setConfirmAction(action);
  };

  const confirmStatusUpdate = async () => {
    const issue = issues.find(issue => issue.id === issueToUpdate);

    if (issue) {
      const { id, casePhotoId } = issue;
      const newStatus = confirmAction === 'close' ? 'closed' : 'open';

      if (!casePhotoId) {
        console.error("Error: casePhotoId is null or undefined.");
        return;
      }

      try {
        const result = await UpdateCase(id, newStatus, casePhotoId, navigate);

        if (result) {
          setIssues(prevIssues =>
            prevIssues.map(issue =>
              issue.id === id ? { ...issue, status: newStatus } : issue
            )
          );
        }
      } catch (error) {
        console.error("Error updating case:", error);
      }
    }

    setIsConfirmModalOpen(false);
  };

  const handleImageClick = (issue) => {
    setSelectedIssue(issue.image);
  };

  const handleIssueClick = (caseId) => {
    navigate(`/issues/${caseId}`);
  };

  const handleSearch = () => {
    const filtered = issues.filter(issue => {
      return (
        (issueId === '' || issue.id === parseInt(issueId)) &&
        (date === '' || new Date(issue.createdAt).toLocaleDateString() === new Date(date).toLocaleDateString()) &&
        (username === '' || issue.description.toLowerCase().includes(username.toLowerCase())) &&
        (userIdFilter === '' || issue.userId.toString().includes(userIdFilter)) &&
        (status === 'all' || issue.status === status)
      );
    });

    setFilteredIssues(filtered.length > 0 ? filtered : null);
  };
  const displayedIssues = filteredIssues || issues;


  return (
    <div className="issue-page">
      <div className="filter-section">

        <div className="filter-field">
          <label>Issue ID</label>
          <input
            type="number"
            value={issueId}
            onChange={(e) => setIssueId(e.target.value)}
          />
        </div>
        <div className="filter-field">
          <label>Date</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="filter-field">
          <label>User ID</label>
          <input
            type="text"
            value={userIdFilter}
            onChange={(e) => setUserIdFilter(e.target.value)}
          />
        </div>
        <div className="filter-field">
          <label>Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="all">All</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
            <option value="pending">Pending</option>
          </select>
        </div>
        <button onClick={handleSearch}>Search</button>
      </div>

      {displayedIssues.length === 0 ? (
        <div className="no-issues-message">
          <p>No issues are available</p>
        </div>
      ) : (
      ['open', 'closed', 'pending'].map(sectionStatus => (
        (filteredIssues === null || filteredIssues.some(issue => issue.status === sectionStatus)) && (
          <div className="issue-section" key={sectionStatus}>
            <h2>{sectionStatus.charAt(0).toUpperCase() + sectionStatus.slice(1)}</h2>
            <div className="issue-cards">
              {(filteredIssues || issues)
                .filter(issue => issue.status === sectionStatus)
                .sort((a, b) => b.id - a.id)
                .map(issue => (
                  <div key={issue.id} className="issue-card" onClick={() => handleIssueClick(issue.id)}>
                    <div className="issue-header">
                      <span>Issue {issue.id}</span>
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/4503/4503859.png"
                        alt="View"
                        className="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageClick(issue);
                        }}
                      />
                    </div>
                    {/* <div className="issue-body">
                      <h3>{issue.description}</h3>
                      <p><strong>User ID:</strong> {issue.userId}</p>
                      <p><strong>Created At:</strong> {issue.createdAt}</p>
                      {sectionStatus === 'pending' && (
                        <>
                          <div className="case-button" onClick={(e) => { e.stopPropagation(); handleStatusUpdate(issue.id, 'reopen'); }}>Reopen Issue</div>
                          <div className="case-button" onClick={(e) => { e.stopPropagation(); handleStatusUpdate(issue.id, 'close'); }}>Close Issue</div>
                        </>
                      )}
                    </div> */}
                    <div className="issue-body">
                      <h3>{issue.description}</h3>
                      <p><strong>User ID:</strong> {issue.userId}</p>
                      <p><strong>Created At:</strong> {issue.createdAt}</p>
                      {sectionStatus === 'pending' && (
                        <div className="button-container">
                          <div className="case-button" onClick={(e) => { e.stopPropagation(); handleStatusUpdate(issue.id, 'reopen'); }}>
                            Reopen Issue
                          </div>
                          <div className="case-button" onClick={(e) => { e.stopPropagation(); handleStatusUpdate(issue.id, 'close'); }}>
                            Close Issue
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                ))}
            </div>
          </div>
        )
      ))
)}
      {/* Confirmation Modal */}
      {isConfirmModalOpen && (
        <div className="close-popup">
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h3>Are you sure you want to {confirmAction} this issue?</h3>
            <div className="buttons">
              <button onClick={confirmStatusUpdate}>Yes, {confirmAction.charAt(0).toUpperCase() + confirmAction.slice(1)} it</button>
              <button onClick={() => setIsConfirmModalOpen(false)}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IssuePage;
