// import React, { useState, useEffect } from 'react';
// import { FaSearch, FaSync } from 'react-icons/fa';
// import { useParams, useNavigate } from 'react-router-dom';
// import { GetTripByIDAPI, CaseByTripId } from '../../Services/APIManager';
// import { fetchMarkerById } from '../../Services/APIManager';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import '../../Styles/mappage.css';
// import { Circle } from 'react-leaflet';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// import Yellowmap from '../../Assets/gps (1).png';
// import Bluemap from '../../Assets/location.png';
// import { useLocation } from 'react-router-dom';
// import Orangemap from '../../Assets/location (2).png';
// const ImageModal = ({ isOpen, onClose, marker, photoUrl }) => {
//   if (!isOpen) return null;
//   return (
//     <div className="tripmodal-overlay">
//       <div className="tripmodal-content">
//         <button className="tripclose-modal" onClick={onClose}>
//           &times;
//         </button>
//         <h4>Marker ID: {marker.markerId}</h4>
//         {photoUrl ? (
//           <img
//             src={photoUrl}
//             alt={`Marker ${marker.markerId}`}
//             style={{ width: '100%', height: 'auto' }}
//           />
//         ) : (
//           <p>No image available for this marker.</p>
//         )}
//       </div>
//     </div>
//   );
// };


// const TripMap = () => {
//   const { tripId } = useParams();
//   const navigate = useNavigate();
//   const [searchQuery, setSearchQuery] = useState(tripId || '');
//   const [tripData, setTripData] = useState(null);
//   const [error, setError] = useState('');
//   const [markerData, setMarkerData] = useState({ readMarkers: [], unreadMarkers: [] });
//   const [mapCenter, setMapCenter] = useState(null);
//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [selectedMarkerPhoto, setSelectedMarkerPhoto] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const [caseData, setCaseData] = useState(null);
//   const [zoomLevel, setZoomLevel] = useState(15);
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   useEffect(() => {
//     if (tripId) {
//       fetchTripData(tripId);
//       fetchCaseData(tripId); // Fetch case data based on tripId
//     }
//   }, [tripId]);



//   const fetchTripData = async (tripId) => {
//     try {
//       const data = await GetTripByIDAPI(tripId);
//       if (data) {
//         setTripData(data.data);
//         setError('');

//         const region = data.data.regions[0];
//         const readMarkers = region.readMarkers;
//         const unreadMarkers = region.unreadMarkers;

//         setMarkerData({ readMarkers, unreadMarkers });

//         if (!mapCenter && unreadMarkers.length > 0) {
//           const firstMarker = unreadMarkers[0];
//           setMapCenter([parseFloat(firstMarker.latitude), parseFloat(firstMarker.longitude)]);
//         }
//       } else {
//         setError('Failed to fetch trip details. Please check the trip ID and try again.');
//       }
//     } catch (error) {
//       setError('Failed to fetch trip details. Please check the trip ID and try again.');
//     }
//   };
//   const fetchCaseData = async (tripId) => {
//     try {
//       const cases = await CaseByTripId(tripId, navigate);

//       // Filter cases with 'open' or 'pending' statuses
//       const openOrPendingCases = cases.data.filter(
//         (caseItem) => caseItem.caseStatus === 'open' || caseItem.caseStatus === 'pending'
//       );

//       setCaseData(openOrPendingCases); // Set filtered cases to be displayed on the map
//     } catch (error) {
//       console.error('Error fetching case data:', error);
//     }
//   };


//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     if (searchQuery) {
//       fetchTripData(searchQuery);
//     }
//   };

//   const handleRefresh = () => {
//     window.location.reload();
//   };

//   const createCustomMarkerIcon = (iconUrl, iconSize = [40, 51], popupAnchor = [5, -30]) => {
//     return new L.Icon({
//       iconUrl: iconUrl,
//       iconRetinaUrl: iconUrl,
//       iconSize: iconSize,
//       iconAnchor: [iconSize[0] / 2, iconSize[1]],
//       popupAnchor: popupAnchor,
//       tooltipAnchor: [16, -28],
//     });
//   };


//   const markerColors = {
//     1: createCustomMarkerIcon(Bluemap, [35, 35]),
//     2: createCustomMarkerIcon(Orangemap, [35, 35]),
//     3: createCustomMarkerIcon(Yellowmap, [35, 35]),
//   };

//   const handleMarkerClick = async (marker) => {
//     setSelectedMarker(marker);
//     setShowPopup(false);

//     try {
//       const markerDetails = await fetchMarkerById(marker.markerId, navigate);
//       if (markerDetails && markerDetails.data.MarkerPhotos.length > 0) {
//         setSelectedMarkerPhoto(markerDetails.data.MarkerPhotos[0].markerPhotoUrl);
//       } else {
//         setSelectedMarkerPhoto(null);
//       }
//       setIsModalOpen(true);
//     } catch (error) {
//       console.error('Error fetching marker details:', error);
//       setSelectedMarkerPhoto(null);
//       setIsModalOpen(true);
//     }
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setSelectedMarker(null);
//     setSelectedMarkerPhoto(null);
//   };

//   const handleTableMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//     setShowPopup(true);
//   };

//   // Handle zoom change
//   const handleZoomChange = (event) => {
//     setZoomLevel(event.target.getZoom());
//   };

//   return (
//     <HelmetProvider>
//       <div className='map-page'>
//         <Helmet>
//           <title>Trip Map</title>
//           <meta name="description" content="View and manage trip details and markers on the map." />
//           <meta name="keywords" content="trip map, markers, GAIL, RFID" />
//         </Helmet>
//         {/* <h3>Trip Details</h3> */}
//         <form onSubmit={handleSearchSubmit}>
//           <div className='search-container'>
//             <input
//               type='text'
//               placeholder='Enter Trip ID to search'
//               value={searchQuery}
//               onChange={handleSearchChange}
//             />
//             <button type='submit'>
//               <FaSearch />
//             </button>
//             <button type='button' onClick={handleRefresh}>
//               <FaSync />
//             </button>
//           </div>
//         </form>

//         {error && <p className='error'>{error}</p>}

//         <div className='map-icon-details'>

//           <div className='marker-legend'>
//             <div className='legend-item'>
//               <img src={markerColors[1].options.iconUrl} alt="Marker Category 1" className='pin-image' />
//               Single pipeline
//             </div>
//             <div className='legend-item'>
//               <img src={markerColors[2].options.iconUrl} alt="Marker Category 2" className='pin-image' />
//               Multiple pipeline
//             </div>
//             <div className='legend-item'>
//               <img src={markerColors[3].options.iconUrl} alt="Marker Category 3" className='pin-image' />
//               Wall chamber
//             </div>
//             <div className='legend-item'>
//               <span className='circle-icon green'></span> Circle (Read)
//             </div>
//             <div className='legend-item'>
//               <span className='circle-icon orange'></span> Circle (Issue)
//             </div>
//           </div>
//         </div>


//         <div className='map-container'>
//           {/* {mapCenter && (
//             <MapContainer center={mapCenter} zoom={16} style={{ height: '80vh', width: '100%' }} onZoomend={handleZoomChange}>
//               <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

//               {markerData.readMarkers.map((marker) => (
//                 <React.Fragment key={marker.markerId}>
//                   <Circle
//                     center={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                     radius={10}
//                     pathOptions={{ color: 'green', fillOpacity: 0.3 }}
//                   />

//                   <Marker
//                     position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                     icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
//                     eventHandlers={{
//                       click: () => handleMarkerClick(marker),
//                     }}
//                   >
//                     <Popup>
//                       <div>
//                         <h4>Marker ID: {marker.markerId}</h4>
//                         <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
//                       </div>
//                     </Popup>
//                   </Marker>
//                 </React.Fragment>
//               ))}

//               {selectedMarker && showPopup && (
//                 <Popup
//                   position={[parseFloat(selectedMarker.latitude), parseFloat(selectedMarker.longitude)]}
//                   onClose={() => setShowPopup(false)}
//                   offset={[0, -20]}
//                 >
//                   <div>
//                     <h4>Marker ID: {selectedMarker.markerId}</h4>
//                     <p>Coordinates: {selectedMarker.latitude}, {selectedMarker.longitude}</p>
//                   </div>
//                 </Popup>
//               )} */}
//                         {mapCenter && (
//             <MapContainer center={mapCenter} zoom={16} style={{ height: '80vh', width: '100%' }} onZoomend={handleZoomChange}>
//               <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />


//               {markerData.readMarkers.map((marker, index) => (
//                 <React.Fragment key={marker.markerId}>

//                   <Circle
//                     center={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                     radius={10}
//                     pathOptions={{ color: 'green', fillOpacity: 0.3 }}
//                   />

//                   <Marker
//                     position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                     icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
//                     eventHandlers={{
//                       click: () => handleMarkerClick(marker),
//                     }}
//                   >
//                     <Popup>
//                       <div>
//                         <h4>Marker ID: {marker.markerId}</h4>
//                         <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
//                       </div>
//                     </Popup>
//                   </Marker>
//                 </React.Fragment>
//               ))}


//               {markerData.readMarkers.map((marker, index) => (
//                 <Marker
//                   key={marker.markerId}
//                   position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                   icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
//                   eventHandlers={{
//                     click: () => handleMarkerClick(marker),
//                   }}
//                 >
//                   <Popup>
//                     <div>
//                       <h4>Marker ID: {marker.markerId}</h4>
//                       <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
//                     </div>
//                   </Popup>
//                 </Marker>
//               ))}

//               {selectedMarker && showPopup && (
//                 <Popup
//                   position={[parseFloat(selectedMarker.latitude), parseFloat(selectedMarker.longitude)]}
//                   onClose={() => setShowPopup(false)}
//                   offset={[0, -20]}
//                 >
//                   <div>
//                     <h4>Marker ID: {selectedMarker.markerId}</h4>
//                     <p>Coordinates: {selectedMarker.latitude}, {selectedMarker.longitude}</p>
//                   </div>
//                 </Popup>
//               )}


//               {caseData && caseData.length > 0 && caseData.map((caseItem, index) => (
//                 <React.Fragment key={index}>
//                   <Circle
//                     center={[parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)]}
//                     radius={10}
//                     pathOptions={{ color: 'orange', fillOpacity: 0.3 }}
//                   />
//                   <Marker
//                     position={[parseFloat(caseItem.latitude), parseFloat(caseItem.longitude)]}

//                   >
//                     <Popup>
//                       <div>
//                         <h4>Case Location</h4>
//                         {caseItem.markerId && <p>Marker ID: {caseItem.markerId}</p>}
//                         <p>Latitude: {caseItem.latitude}</p>
//                         <p>Longitude: {caseItem.longitude}</p>
//                         <p>Status: {caseItem.caseStatus}</p>
//                       </div>
//                     </Popup>
//                   </Marker>
//                 </React.Fragment>
//               ))}

//             </MapContainer>
//           )}

//         </div>



//         {tripData && (
//           <div className='trip-info'>
//             <p><span>Trip ID:</span> {tripData.tripId}</p>
//             <p><span>User ID:</span> {tripData.userId}</p>
//             <p><span>Shift ID:</span> {tripData.shiftId}</p>
//             <p><span>Start Time:</span> {new Date(tripData.startTime).toLocaleString()}</p>
//             <p><span>End Time:</span> {new Date(tripData.endTime).toLocaleString()}</p>
//           </div>
//         )}

//         <ImageModal
//           isOpen={isModalOpen}
//           onClose={handleCloseModal}
//           marker={selectedMarker}
//           photoUrl={selectedMarkerPhoto}
//         />
//       </div>

//     </HelmetProvider>
//   );
// };

// export default TripMap;
import React, { useState, useEffect } from 'react';
import { FaSearch, FaSync } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { GetTripByIDAPI, CaseByTripId } from '../../Services/APIManager';
import { fetchMarkerById } from '../../Services/APIManager';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../../Styles/mappage.css';
import { Circle } from 'react-leaflet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Yellowmap from '../../Assets/gps (1).png';
import Bluemap from '../../Assets/location.png';
import { useLocation } from 'react-router-dom';
import Orangemap from '../../Assets/location (2).png';
const ImageModal = ({ isOpen, onClose, marker, photoUrl }) => {
  if (!isOpen) return null;
  return (
    <div className="tripmodal-overlay">
      <div className="tripmodal-content">
        <button className="tripclose-modal" onClick={onClose}>
          &times;
        </button>
        <h4>Marker ID: {marker.markerId}</h4>
        {photoUrl ? (
          <img
            src={photoUrl}
            alt={`Marker ${marker.markerId}`}
            style={{ width: '100%', height: 'auto' }}
          />
        ) : (
          <p>No image available for this marker.</p>
        )}
      </div>
    </div>
  );
};


const TripMap = () => {
  const { tripId } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(tripId || '');
  const [tripData, setTripData] = useState(null);
  const [error, setError] = useState('');
  const [markerData, setMarkerData] = useState({ readMarkers: [], unreadMarkers: [] });
  const [mapCenter, setMapCenter] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerPhoto, setSelectedMarkerPhoto] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [caseData, setCaseData] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(15);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (tripId) {
      fetchTripData(tripId);
      fetchCaseData(tripId); // Fetch case data based on tripId
    }
  }, [tripId]);



  const fetchTripData = async (tripId) => {
    try {
      const data = await GetTripByIDAPI(tripId);
      if (data) {
        setTripData(data.data);
        setError('');

        const region = data.data.regions[0];
        const readMarkers = region.readMarkers;
        const unreadMarkers = region.unreadMarkers;

        setMarkerData({ readMarkers, unreadMarkers });

        if (!mapCenter && unreadMarkers.length > 0) {
          const firstMarker = unreadMarkers[0];
          setMapCenter([parseFloat(firstMarker.latitude), parseFloat(firstMarker.longitude)]);
        }
      } else {
        setError('Failed to fetch trip details. Please check the trip ID and try again.');
      }
    } catch (error) {
      setError('Failed to fetch trip details. Please check the trip ID and try again.');
    }
  };
  const fetchCaseData = async (tripId) => {
    try {
      const cases = await CaseByTripId(tripId, navigate);

      // Filter cases with 'open' or 'pending' statuses
      const openOrPendingCases = cases.data.filter(
        (caseItem) => caseItem.caseStatus === 'open' || caseItem.caseStatus === 'pending'
      );

      setCaseData(openOrPendingCases); // Set filtered cases to be displayed on the map
    } catch (error) {
      console.error('Error fetching case data:', error);
    }
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      fetchTripData(searchQuery);
    }
  };
 
  const handleRefresh = () => {
    window.location.reload();
  };

  const createCustomMarkerIcon = (iconUrl, iconSize = [40, 51], popupAnchor = [5, -30]) => {
    return new L.Icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      iconSize: iconSize,
      iconAnchor: [iconSize[0] / 2, iconSize[1]],
      popupAnchor: popupAnchor,
      tooltipAnchor: [16, -28],
    });
  };


  const markerColors = {
    1: createCustomMarkerIcon(Bluemap, [35, 35]),
    2: createCustomMarkerIcon(Orangemap, [35, 35]),
    3: createCustomMarkerIcon(Yellowmap, [35, 35]),
  };

  const handleMarkerClick = async (marker) => {
    setSelectedMarker(marker);
    setShowPopup(false);

    try {
      const markerDetails = await fetchMarkerById(marker.markerId, navigate);
      if (markerDetails && markerDetails.data.MarkerPhotos.length > 0) {
        setSelectedMarkerPhoto(markerDetails.data.MarkerPhotos[0].markerPhotoUrl);
      } else {
        setSelectedMarkerPhoto(null);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching marker details:', error);
      setSelectedMarkerPhoto(null);
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMarker(null);
    setSelectedMarkerPhoto(null);
  };

  const handleTableMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setShowPopup(true);
  };

  // Handle zoom change
  const handleZoomChange = (event) => {
    setZoomLevel(event.target.getZoom());
  };

  return (
    <HelmetProvider>
      <div className='map-page'>
        <Helmet>
          <title>Trip Map</title>
          <meta name="description" content="View and manage trip details and markers on the map." />
          <meta name="keywords" content="trip map, markers, GAIL, RFID" />
        </Helmet>
        {/* <h3>Trip Details</h3> */}
        <form onSubmit={handleSearchSubmit}>
          <div className='search-container'>
            <input
              type='text'
              placeholder='Enter Trip ID to search'
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button type='submit'>
              <FaSearch />
            </button>
            <button type='button' onClick={handleRefresh}>
              <FaSync />
            </button>
          </div>
        </form>

        {error && <p className='error'>{error}</p>}

        <div className='map-icon-details'>

          <div className='marker-legend'>
            <div className='legend-item'>
              <img src={markerColors[1].options.iconUrl} alt="Marker Category 1" className='pin-image' />
              Single pipeline
            </div>
            <div className='legend-item'>
              <img src={markerColors[2].options.iconUrl} alt="Marker Category 2" className='pin-image' />
              Multiple pipeline
            </div>
            <div className='legend-item'>
              <img src={markerColors[3].options.iconUrl} alt="Marker Category 3" className='pin-image' />
              Wall chamber
            </div>
            <div className='legend-item'>
              <span className='circle-icon green'></span> Circle (Read)
            </div>
            <div className='legend-item'>
              <span className='circle-icon orange'></span> Circle (Issue)
            </div>
          </div>
        </div>


        <div className='map-container'>
          {/* {mapCenter && (
            <MapContainer center={mapCenter} zoom={16} style={{ height: '80vh', width: '100%' }} onZoomend={handleZoomChange}>
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

              {markerData.readMarkers.map((marker) => (
                <React.Fragment key={marker.markerId}>
                  <Circle
                    center={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                    radius={10}
                    pathOptions={{ color: 'green', fillOpacity: 0.3 }}
                  />

                  <Marker
                    position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                    icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
                    eventHandlers={{
                      click: () => handleMarkerClick(marker),
                    }}
                  >
                    <Popup>
                      <div>
                        <h4>Marker ID: {marker.markerId}</h4>
                        <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
                      </div>
                    </Popup>
                  </Marker>
                </React.Fragment>
              ))}

              {selectedMarker && showPopup && (
                <Popup
                  position={[parseFloat(selectedMarker.latitude), parseFloat(selectedMarker.longitude)]}
                  onClose={() => setShowPopup(false)}
                  offset={[0, -20]}
                >
                  <div>
                    <h4>Marker ID: {selectedMarker.markerId}</h4>
                    <p>Coordinates: {selectedMarker.latitude}, {selectedMarker.longitude}</p>
                  </div>
                </Popup>
              )} */}
          {mapCenter && (
            <MapContainer center={mapCenter} zoom={16} style={{ height: '80vh', width: '100%' }} onZoomend={handleZoomChange}>
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />


              {markerData.readMarkers.map((marker, index) => (
                <React.Fragment key={marker.markerId}>

                  <Circle
                    center={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                    radius={10}
                    pathOptions={{ color: 'green', fillOpacity: 0.3 }}
                  />

                  <Marker
                    position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                    icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
                    eventHandlers={{
                      click: () => handleMarkerClick(marker),
                    }}
                  >
                    <Popup>
                      <div>
                        <h4>Marker ID: {marker.markerId}</h4>
                        <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
                      </div>
                    </Popup>
                  </Marker>
                </React.Fragment>
              ))}


              {markerData.readMarkers.map((marker, index) => (
                <Marker
                  key={marker.markerId}
                  position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                  icon={markerColors[marker.markerCategoryId] || createCustomMarkerIcon('#000000')}
                  eventHandlers={{
                    click: () => handleMarkerClick(marker),
                  }}
                >
                  <Popup>
                    <div>
                      <h4>Marker ID: {marker.markerId}</h4>
                      <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
                    </div>
                  </Popup>
                </Marker>
              ))}

              {selectedMarker && showPopup && (
                <Popup
                  position={[parseFloat(selectedMarker.latitude), parseFloat(selectedMarker.longitude)]}
                  onClose={() => setShowPopup(false)}
                  offset={[0, -20]}
                >
                  <div>
                    <h4>Marker ID: {selectedMarker.markerId}</h4>
                    <p>Coordinates: {selectedMarker.latitude}, {selectedMarker.longitude}</p>
                  </div>
                </Popup>
              )}


{caseData && caseData.length > 0 ? (
        caseData.map((caseItem) => {
          const latitude = parseFloat(caseItem.latitude);
          const longitude = parseFloat(caseItem.longitude);
          const icon = markerColors[caseItem.caseType.caseTypeId] || createCustomMarkerIcon('#000000');

          const handlePinClick = () => {
            navigate(`/issues/${caseItem.caseId}`); 
          };

          return (
            <React.Fragment key={caseItem.caseId}>
              <Circle
                center={[latitude, longitude]}
                radius={10}
                pathOptions={{ color: 'orange', fillOpacity: 0.3 }}
              />
              <Marker
                position={[latitude, longitude]}
                icon={icon}
                eventHandlers={{ click: handlePinClick }}
              >
                <Popup>
                  <div>
                    <h4>Case Location</h4>
                    {caseItem.markerId && <p>Marker ID: {caseItem.markerId}</p>}
                    <p>Latitude: {caseItem.latitude}</p>
                    <p>Longitude: {caseItem.longitude}</p>
                    <p>Status: {caseItem.caseStatus}</p>
                    <p>Case Type: {caseItem.caseType.caseName}</p>
                  </div>
                </Popup>
              </Marker>
            </React.Fragment>
          );
        })
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>No unread cases available.</p>
        </div>
      )}


            </MapContainer>
          )}

        </div>



        {tripData && (
          <div className='trip-info'>
            <p><span>Trip ID:</span> {tripData.tripId}</p>
            <p><span>User ID:</span> {tripData.userId}</p>
            <p><span>Shift ID:</span> {tripData.shiftId}</p>
            <p><span>Start Time:</span> {new Date(tripData.startTime).toLocaleString()}</p>
            <p><span>End Time:</span> {new Date(tripData.endTime).toLocaleString()}</p>
          </div>
        )}

        <ImageModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          marker={selectedMarker}
          photoUrl={selectedMarkerPhoto}
        />
      </div>

    </HelmetProvider>
  );
};

export default TripMap;