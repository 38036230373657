// import React, { useEffect, useState } from 'react';
// import '../../Styles/card.css';
// import { useLocation } from 'react-router-dom';
// import { StatusAPI} from '../../Services/APIManager';
// import { useNavigate } from "react-router-dom";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

// export default function Cards() {
//   let navigate = useNavigate();
//   const { pathname } = useLocation();
//   const [totalRegions, setTotalRegions] = useState(0);
//   const [dashboardStats, setDashboardStats] = useState({
//     totalRiders: 0,
//     totalMarkers: 0,
//     totalMarkersMissing: 0,
//     totalRiderUsers: 0,
//     currentLiveTrips: 0,
//     totalTrips: 0,
//     totalLiveTrips:0
//   });

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   const handleApiError = (error) => {
//     if (error?.status === 401) {
//       localStorage.clear();
//       navigate("/login");
//     } else {
//       console.error("API Error:", error);
//     }
//   };

  

//   useEffect(() => {
//     async function fetchDashboardStats() {
//       try {
//         let res = await StatusAPI();
//         console.log("StatusAPI Response:", res); 
//         if (res?.data !== undefined) {
//           setDashboardStats(res.data);
//         } else {
//           console.log("No dashboard stats data found");
//         }
//       } catch (e) {
//         handleApiError(e);
//       }
//     }
//     fetchDashboardStats();
//   }, []);

//   return (
//     <HelmetProvider>
//     <div className='cards'>
//       <Helmet>
//         <title>Dashboard - GAIL Marker Surveillance System</title>
//         <meta name="description" content="Overview of the GAIL Marker Surveillance System including total riders, markers, regions, and live trips." />
//         <meta name="keywords" content="GAIL, Marker Surveillance, Dashboard, Trips, Regions" />
//       </Helmet>
//       <h3>Dashboard</h3>
//       <div className='card-container'>
//         <div className='card' onClick={() => navigate("/getusers")}>
//           <h4>Total Riders</h4>
//           <p>{dashboardStats.totalRiders}</p>
//         </div>
//         <div className='card' onClick={() => navigate("/markers-map")}>
//           <h4>Total Markers</h4>
//           <p>{dashboardStats.totalMarkers}</p>
//         </div>
//         <div className='card' onClick={() => navigate("/regions")}>
//           <h4>Total Regions</h4>
//           <p>{dashboardStats.totalRegions}</p>
//         </div>
//         <div className='card'>
//           <h4>Total Markers Missing</h4>
//           <p>{totalMarkersMissing}</p>
//         </div>
//         <div className='card' onClick={() => navigate("/trip-page")}>
//           <h4>Total Trips</h4>
//           <p>{dashboardStats.totalTrips}</p>
//         </div>
//         <div className='card'>
//           <h4>Current Live Trips</h4>
//           <p>{dashboardStats.totalLiveTrips}</p>
//         </div>
//       </div>
//     </div>
//     </HelmetProvider>
//   );
// }
import React, { useEffect, useState } from 'react';
import '../../Styles/card.css';
import { useLocation } from 'react-router-dom';
import { StatusAPI, GetMissingMarkers } from '../../Services/APIManager';
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Cards() {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [dashboardStats, setDashboardStats] = useState({
    totalRiders: 0,
    totalMarkers: 0,
    totalMarkersMissing: 0,
    totalRiderUsers: 0,
    currentLiveTrips: 0,
    totalTrips: 0,
    totalLiveTrips: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleApiError = (error) => {
    if (error?.status === 401) {
      localStorage.clear();
      navigate("/login");
    } else {
      console.error("API Error:", error);
    }
  };
  useEffect(() => {
    async function fetchDashboardStats() {
      try {
        let res = await StatusAPI();
        console.log("StatusAPI Response:", res); 
        if (res?.data !== undefined) {
          setDashboardStats(prevStats => ({
            ...prevStats,
            ...res.data
          }));
        } else {
          console.log("No dashboard stats data found");
        }
      } catch (e) {
        handleApiError(e);
      }
    }

    async function fetchMissingMarkers() {
      try {
        let res = await GetMissingMarkers(navigate);
        if (res?.data) {
          setDashboardStats(prevStats => ({
            ...prevStats,
            totalMarkersMissing: res.data.length
          }));
        } else {
          console.log("No missing markers data found");
        }
      } catch (e) {
        handleApiError(e);
      }
    }

    fetchDashboardStats();
    fetchMissingMarkers(); 
  }, [navigate]);

  return (
    <HelmetProvider>
      <div className='cards'>
        <Helmet>
          <title>Dashboard - GAIL Marker Surveillance System</title>
          <meta name="description" content="Overview of the GAIL Marker Surveillance System including total riders, markers, regions, and live trips." />
          <meta name="keywords" content="GAIL, Marker Surveillance, Dashboard, Trips, Regions" />
        </Helmet>
        <h3>Dashboard</h3>
        <div className='card-container'>
          <div className='card' onClick={() => navigate("/getusers")}>
            <h4>Total Riders</h4>
            <p>{dashboardStats.totalRiders}</p>
          </div>
          <div className='card' onClick={() => navigate("/markers-map")}>
            <h4>Total Markers</h4>
            <p>{dashboardStats.totalMarkers}</p>
          </div>
          <div className='card' onClick={() => navigate("/regions")}>
            <h4>Total Regions</h4>
            <p>{dashboardStats.totalRegions}</p>
          </div>
          <div className='card' onClick={() => navigate("/livelocation")}>
            <h4>Total Markers Missing</h4>
            <p>{dashboardStats.totalMarkersMissing}</p> 
          </div>
          <div className='card' onClick={() => navigate("/trip-page")}>
            <h4>Total Trips</h4>
            <p>{dashboardStats.totalTrips}</p>
          </div>
          <div className='card' onClick={() => navigate("/livelocation")}>
            <h4>Current Live Trips</h4>
            <p>{dashboardStats.totalLiveTrips}</p>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
